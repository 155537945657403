/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActivityDashboardGrandTotalsResponse } from '../models/activity-dashboard-grand-totals-response';
import { ActivityDashboardResponse } from '../models/activity-dashboard-response';
import { ActivityDashboardTimeSeriesTotalsResponse } from '../models/activity-dashboard-time-series-totals-response';
import { ActivityDashboardTopByAgentsResponse } from '../models/activity-dashboard-top-by-agents-response';
import { ActivityDashboardTopByGroupsResponse } from '../models/activity-dashboard-top-by-groups-response';
import { activityDashboardV2GetActivityDashboardData } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-data';
import { ActivityDashboardV2GetActivityDashboardData$Params } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-data';
import { activityDashboardV2GetActivityDashboardGrandTotals } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-grand-totals';
import { ActivityDashboardV2GetActivityDashboardGrandTotals$Params } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-grand-totals';
import { activityDashboardV2GetActivityDashboardTimeSeriesTotals } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-time-series-totals';
import { ActivityDashboardV2GetActivityDashboardTimeSeriesTotals$Params } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-time-series-totals';
import { activityDashboardV2GetActivityDashboardTopByAgentTotals } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-top-by-agent-totals';
import { ActivityDashboardV2GetActivityDashboardTopByAgentTotals$Params } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-top-by-agent-totals';
import { activityDashboardV2GetActivityDashboardTopByGroupTotals } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-top-by-group-totals';
import { ActivityDashboardV2GetActivityDashboardTopByGroupTotals$Params } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-top-by-group-totals';

@Injectable({ providedIn: 'root' })
export class ActivityDashboardService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardData()` */
  static readonly ActivityDashboardV2GetActivityDashboardDataPath = '/api/reports-v2/activity-dashboard-data';

  /**
   * Get activity dashboard data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardData$Response(params: ActivityDashboardV2GetActivityDashboardData$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityDashboardResponse>> {
    return activityDashboardV2GetActivityDashboardData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get activity dashboard data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardData(params: ActivityDashboardV2GetActivityDashboardData$Params, context?: HttpContext): Observable<ActivityDashboardResponse> {
    return this.activityDashboardV2GetActivityDashboardData$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardResponse>): ActivityDashboardResponse => r.body)
    );
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardGrandTotals()` */
  static readonly ActivityDashboardV2GetActivityDashboardGrandTotalsPath = '/api/reports-v2/activity-dashboard-grand-totals';

  /**
   * Get activity dashboard grand totals.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardGrandTotals()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardGrandTotals$Response(params: ActivityDashboardV2GetActivityDashboardGrandTotals$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityDashboardGrandTotalsResponse>> {
    return activityDashboardV2GetActivityDashboardGrandTotals(this.http, this.rootUrl, params, context);
  }

  /**
   * Get activity dashboard grand totals.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardGrandTotals$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardGrandTotals(params: ActivityDashboardV2GetActivityDashboardGrandTotals$Params, context?: HttpContext): Observable<ActivityDashboardGrandTotalsResponse> {
    return this.activityDashboardV2GetActivityDashboardGrandTotals$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardGrandTotalsResponse>): ActivityDashboardGrandTotalsResponse => r.body)
    );
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardTimeSeriesTotals()` */
  static readonly ActivityDashboardV2GetActivityDashboardTimeSeriesTotalsPath = '/api/reports-v2/activity-dashboard-time-series-totals';

  /**
   * Get activity dashboard time series.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardTimeSeriesTotals()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTimeSeriesTotals$Response(params: ActivityDashboardV2GetActivityDashboardTimeSeriesTotals$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityDashboardTimeSeriesTotalsResponse>> {
    return activityDashboardV2GetActivityDashboardTimeSeriesTotals(this.http, this.rootUrl, params, context);
  }

  /**
   * Get activity dashboard time series.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardTimeSeriesTotals$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTimeSeriesTotals(params: ActivityDashboardV2GetActivityDashboardTimeSeriesTotals$Params, context?: HttpContext): Observable<ActivityDashboardTimeSeriesTotalsResponse> {
    return this.activityDashboardV2GetActivityDashboardTimeSeriesTotals$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardTimeSeriesTotalsResponse>): ActivityDashboardTimeSeriesTotalsResponse => r.body)
    );
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardTopByGroupTotals()` */
  static readonly ActivityDashboardV2GetActivityDashboardTopByGroupTotalsPath = '/api/reports-v2/activity-dashboard-top-groups';

  /**
   * Get activity dashboard top groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardTopByGroupTotals()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTopByGroupTotals$Response(params: ActivityDashboardV2GetActivityDashboardTopByGroupTotals$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityDashboardTopByGroupsResponse>> {
    return activityDashboardV2GetActivityDashboardTopByGroupTotals(this.http, this.rootUrl, params, context);
  }

  /**
   * Get activity dashboard top groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardTopByGroupTotals$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTopByGroupTotals(params: ActivityDashboardV2GetActivityDashboardTopByGroupTotals$Params, context?: HttpContext): Observable<ActivityDashboardTopByGroupsResponse> {
    return this.activityDashboardV2GetActivityDashboardTopByGroupTotals$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardTopByGroupsResponse>): ActivityDashboardTopByGroupsResponse => r.body)
    );
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardTopByAgentTotals()` */
  static readonly ActivityDashboardV2GetActivityDashboardTopByAgentTotalsPath = '/api/reports-v2/activity-dashboard-top-agents';

  /**
   * Get activity dashboard top groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardTopByAgentTotals()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTopByAgentTotals$Response(params: ActivityDashboardV2GetActivityDashboardTopByAgentTotals$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityDashboardTopByAgentsResponse>> {
    return activityDashboardV2GetActivityDashboardTopByAgentTotals(this.http, this.rootUrl, params, context);
  }

  /**
   * Get activity dashboard top groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardTopByAgentTotals$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTopByAgentTotals(params: ActivityDashboardV2GetActivityDashboardTopByAgentTotals$Params, context?: HttpContext): Observable<ActivityDashboardTopByAgentsResponse> {
    return this.activityDashboardV2GetActivityDashboardTopByAgentTotals$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardTopByAgentsResponse>): ActivityDashboardTopByAgentsResponse => r.body)
    );
  }

}
