/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AutomationSettingsRequest } from '../../models/automation-settings-request';
import { AutomationSettingsResponse } from '../../models/automation-settings-response';

export interface MessagingAutomationGetAutomationList$Params {
      body: AutomationSettingsRequest
}

export function messagingAutomationGetAutomationList(http: HttpClient, rootUrl: string, params: MessagingAutomationGetAutomationList$Params, context?: HttpContext): Observable<StrictHttpResponse<AutomationSettingsResponse>> {
  const rb = new RequestBuilder(rootUrl, messagingAutomationGetAutomationList.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AutomationSettingsResponse>;
    })
  );
}

messagingAutomationGetAutomationList.PATH = '/api/messaging-automation/list';
