/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { faxDeleteFax } from '../fn/fax/fax-delete-fax';
import { FaxDeleteFax$Params } from '../fn/fax/fax-delete-fax';
import { faxDownloadFax } from '../fn/fax/fax-download-fax';
import { FaxDownloadFax$Params } from '../fn/fax/fax-download-fax';
import { faxGetGetIncomingFaxes } from '../fn/fax/fax-get-get-incoming-faxes';
import { FaxGetGetIncomingFaxes$Params } from '../fn/fax/fax-get-get-incoming-faxes';
import { faxGetMyAllFaxNumbers } from '../fn/fax/fax-get-my-all-fax-numbers';
import { FaxGetMyAllFaxNumbers$Params } from '../fn/fax/fax-get-my-all-fax-numbers';
import { faxGetMyIncomingFaxNumbers } from '../fn/fax/fax-get-my-incoming-fax-numbers';
import { FaxGetMyIncomingFaxNumbers$Params } from '../fn/fax/fax-get-my-incoming-fax-numbers';
import { faxGetMyOutgoingFaxNumbers } from '../fn/fax/fax-get-my-outgoing-fax-numbers';
import { FaxGetMyOutgoingFaxNumbers$Params } from '../fn/fax/fax-get-my-outgoing-fax-numbers';
import { FaxGridResponse } from '../models/fax-grid-response';
import { FaxPhoneNumberModel } from '../models/fax-phone-number-model';
import { faxPreviewFax } from '../fn/fax/fax-preview-fax';
import { FaxPreviewFax$Params } from '../fn/fax/fax-preview-fax';
import { faxRetryFax } from '../fn/fax/fax-retry-fax';
import { FaxRetryFax$Params } from '../fn/fax/fax-retry-fax';
import { faxSendFax } from '../fn/fax/fax-send-fax';
import { FaxSendFax$Params } from '../fn/fax/fax-send-fax';
import { faxUpdateFaxNotes } from '../fn/fax/fax-update-fax-notes';
import { FaxUpdateFaxNotes$Params } from '../fn/fax/fax-update-fax-notes';

@Injectable({ providedIn: 'root' })
export class FaxService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `faxGetMyIncomingFaxNumbers()` */
  static readonly FaxGetMyIncomingFaxNumbersPath = '/api/fax/my-incoming-fax-numbers';

  /**
   * Get my incoming fax numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxGetMyIncomingFaxNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxGetMyIncomingFaxNumbers$Response(params?: FaxGetMyIncomingFaxNumbers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FaxPhoneNumberModel>>> {
    return faxGetMyIncomingFaxNumbers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get my incoming fax numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxGetMyIncomingFaxNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxGetMyIncomingFaxNumbers(params?: FaxGetMyIncomingFaxNumbers$Params, context?: HttpContext): Observable<Array<FaxPhoneNumberModel>> {
    return this.faxGetMyIncomingFaxNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FaxPhoneNumberModel>>): Array<FaxPhoneNumberModel> => r.body)
    );
  }

  /** Path part for operation `faxGetMyOutgoingFaxNumbers()` */
  static readonly FaxGetMyOutgoingFaxNumbersPath = '/api/fax/my-outgoing-fax-numbers';

  /**
   * Get my incoming fax numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxGetMyOutgoingFaxNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxGetMyOutgoingFaxNumbers$Response(params?: FaxGetMyOutgoingFaxNumbers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FaxPhoneNumberModel>>> {
    return faxGetMyOutgoingFaxNumbers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get my incoming fax numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxGetMyOutgoingFaxNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxGetMyOutgoingFaxNumbers(params?: FaxGetMyOutgoingFaxNumbers$Params, context?: HttpContext): Observable<Array<FaxPhoneNumberModel>> {
    return this.faxGetMyOutgoingFaxNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FaxPhoneNumberModel>>): Array<FaxPhoneNumberModel> => r.body)
    );
  }

  /** Path part for operation `faxGetMyAllFaxNumbers()` */
  static readonly FaxGetMyAllFaxNumbersPath = '/api/fax/my-all-fax-numbers';

  /**
   * Get my incoming fax numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxGetMyAllFaxNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxGetMyAllFaxNumbers$Response(params?: FaxGetMyAllFaxNumbers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FaxPhoneNumberModel>>> {
    return faxGetMyAllFaxNumbers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get my incoming fax numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxGetMyAllFaxNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxGetMyAllFaxNumbers(params?: FaxGetMyAllFaxNumbers$Params, context?: HttpContext): Observable<Array<FaxPhoneNumberModel>> {
    return this.faxGetMyAllFaxNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FaxPhoneNumberModel>>): Array<FaxPhoneNumberModel> => r.body)
    );
  }

  /** Path part for operation `faxGetGetIncomingFaxes()` */
  static readonly FaxGetGetIncomingFaxesPath = '/api/fax/get-faxes';

  /**
   * Get incoming/outgoing faxes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxGetGetIncomingFaxes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxGetGetIncomingFaxes$Response(params: FaxGetGetIncomingFaxes$Params, context?: HttpContext): Observable<StrictHttpResponse<FaxGridResponse>> {
    return faxGetGetIncomingFaxes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get incoming/outgoing faxes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxGetGetIncomingFaxes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxGetGetIncomingFaxes(params: FaxGetGetIncomingFaxes$Params, context?: HttpContext): Observable<FaxGridResponse> {
    return this.faxGetGetIncomingFaxes$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaxGridResponse>): FaxGridResponse => r.body)
    );
  }

  /** Path part for operation `faxPreviewFax()` */
  static readonly FaxPreviewFaxPath = '/api/fax/preview-fax/{callerId}/{id}';

  /**
   * Preview Fax.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxPreviewFax()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxPreviewFax$Response(params: FaxPreviewFax$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return faxPreviewFax(this.http, this.rootUrl, params, context);
  }

  /**
   * Preview Fax.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxPreviewFax$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxPreviewFax(params: FaxPreviewFax$Params, context?: HttpContext): Observable<void> {
    return this.faxPreviewFax$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `faxDownloadFax()` */
  static readonly FaxDownloadFaxPath = '/api/fax/download-fax/{callerId}/{id}';

  /**
   * Download Fax.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxDownloadFax()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxDownloadFax$Response(params: FaxDownloadFax$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return faxDownloadFax(this.http, this.rootUrl, params, context);
  }

  /**
   * Download Fax.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxDownloadFax$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxDownloadFax(params: FaxDownloadFax$Params, context?: HttpContext): Observable<void> {
    return this.faxDownloadFax$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `faxDeleteFax()` */
  static readonly FaxDeleteFaxPath = '/api/fax/fax/{callerId}/{id}';

  /**
   * Delete Fax.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxDeleteFax()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxDeleteFax$Response(params: FaxDeleteFax$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return faxDeleteFax(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Fax.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxDeleteFax$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxDeleteFax(params: FaxDeleteFax$Params, context?: HttpContext): Observable<void> {
    return this.faxDeleteFax$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `faxSendFax()` */
  static readonly FaxSendFaxPath = '/api/fax/send-fax';

  /**
   * Send Fax.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxSendFax()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  faxSendFax$Response(params: FaxSendFax$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return faxSendFax(this.http, this.rootUrl, params, context);
  }

  /**
   * Send Fax.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxSendFax$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  faxSendFax(params: FaxSendFax$Params, context?: HttpContext): Observable<void> {
    return this.faxSendFax$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `faxRetryFax()` */
  static readonly FaxRetryFaxPath = '/api/fax/retry-fax';

  /**
   * Retry Failed Fax.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxRetryFax()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxRetryFax$Response(params: FaxRetryFax$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return faxRetryFax(this.http, this.rootUrl, params, context);
  }

  /**
   * Retry Failed Fax.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxRetryFax$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxRetryFax(params: FaxRetryFax$Params, context?: HttpContext): Observable<void> {
    return this.faxRetryFax$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `faxUpdateFaxNotes()` */
  static readonly FaxUpdateFaxNotesPath = '/api/fax/update-fax-notes';

  /**
   * Update Fax Internal Notes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxUpdateFaxNotes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxUpdateFaxNotes$Response(params: FaxUpdateFaxNotes$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return faxUpdateFaxNotes(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Fax Internal Notes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxUpdateFaxNotes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxUpdateFaxNotes(params: FaxUpdateFaxNotes$Params, context?: HttpContext): Observable<void> {
    return this.faxUpdateFaxNotes$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
