import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PhoneNumberPipe} from './pipes/phone-number.pipe';
import {DurationPipe} from './pipes/duration.pipe';
import {TextInQuotesPipe} from './pipes/text-in-quotes.pipe';
import {CardOptionsComponent} from './visual/card/options-card.component';
import {CardComponent} from './visual/card/card.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SearchTextPipe} from './pipes/search-text.pipe';
import {AudioComponent} from './visual/audio/audio.component';
import {ExplanationComponent} from './visual/explanation/explanation.component';
import {LogPipe} from './pipes/log.pipe';
import {HumanizedDatePipe} from './pipes/humanized-date.pipe';
import {DateTimePipe} from './pipes/date-time.pipe';
import {InitialsPipe} from './pipes/initials.pipe';
import {HumanizedDurationPipe} from './pipes/humanized-duration.pipe';
import {DroppableDirective} from './visual/droppable/droppable.directive';
import {ModulesFilterPipe} from './pipes/modules-filter.pipe';
import {FileSizePipe} from './pipes/file-size.pipe';
import {DateRangeService} from './services/date-range.service';
import {TagComponent} from './visual/tags/tag.component';
import {TagsComponent} from './visual/tags/tags.component';
import {WeekDayPipe} from './pipes/week-day.pipe';
import {RepeatFrequencyTextPipe} from './pipes/repeat-frequency-text.pipe';
import {HighlightPipe} from './pipes/highlight.pipe';
import {ChartDefaultsService} from './services/chart-defaults.service';
import {AuditTypePipe} from './pipes/audit-type.pipe';
import {YesNoPipe} from './pipes/yesno.pipe';
import {UnixTimePipe} from './pipes/unix-time.pipe';
import {ShowHideComponent} from './visual/show-hide/show-hide.component';
import {SmsContactsComponent} from './visual/sms-contacts/sms-contacts.component';
import {EmailPipe} from './pipes/email.pipe';
import {NameInitialsPipe} from './pipes/name-initials.pipe';
import {BaseComponent} from './base.component';
import {InViewComponent} from './visual/in-view/in-view.component';
import {InViewDirective} from './visual/in-view/in-view.directive';
import {DynamicPageService} from './visual/dynamic-page/dynamic-page.service';
import {DynamicPageComponent} from './visual/dynamic-page/dynamic-page.component';
import {FlexModule} from '@angular/flex-layout';
import {AudioControlComponent} from './visual/audio-control/audio-control.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSliderModule} from '@angular/material/slider';
import {SecondAsDurationPipe} from './pipes/second-as-duration.pipe';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatInputModule} from '@angular/material/input';
import {FilterRowAdditionalContentComponent} from '../dialogs/grid-filter/filter-row-additional-content.component';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {FilterSecondRowAdditionalContentComponent} from '../dialogs/grid-filter/filter-second-row-additional-content.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {GridSortService} from './services/grid-sort.service';
import {PbxDateTimePipe} from './pipes/pbx-date-time.pipe';
import {LanguagePipe} from './pipes/language.pipe';
import {WhatsAppStatusPipe} from './pipes/whats-app-status.pipe';
import {WhatsAppCategoryPipe} from './pipes/whats-app-category.pipe';
import {MatCardModule} from '@angular/material/card';
import {MacAddressPipe} from './pipes/mac-address.pipe';
import {WrappingPipe} from './pipes/wrapping.pipe';
import {WorkflowActionPanelComponent} from './visual/work-flow-editor/action-panel/workflow-action-panel.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        FlexModule,
        MatTooltipModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatSliderModule,
        OverlayModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatChipsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatDialogModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatCardModule,
    ],
  declarations: [
    BaseComponent,
    WrappingPipe,
    LogPipe,
    UnixTimePipe,
    WeekDayPipe,
    HighlightPipe,
    RepeatFrequencyTextPipe,
    PhoneNumberPipe,
    DurationPipe,
    SecondAsDurationPipe,
    TextInQuotesPipe,
    SearchTextPipe,
    HumanizedDatePipe,
    HumanizedDurationPipe,
    DateTimePipe,
    PbxDateTimePipe,
    FileSizePipe,
    InitialsPipe,
    ModulesFilterPipe,
    AuditTypePipe,
    YesNoPipe,
    EmailPipe,
    NameInitialsPipe,
    LanguagePipe,
    WhatsAppStatusPipe,
    WhatsAppCategoryPipe,
    MacAddressPipe,
    CardComponent,
    CardOptionsComponent,
    AudioComponent,
    AudioControlComponent,
    ExplanationComponent,
    DroppableDirective,
    TagComponent,
    TagsComponent,
    ShowHideComponent,
    SmsContactsComponent,
    InViewComponent,
    InViewDirective,
    DynamicPageComponent,
    FilterRowAdditionalContentComponent,
    FilterSecondRowAdditionalContentComponent,
    WorkflowActionPanelComponent,
  ],
  providers: [
    DateRangeService,
    GridSortService,
    ChartDefaultsService,
    DynamicPageService,
  ],
  exports: [
    WrappingPipe,
    LogPipe,
    UnixTimePipe,
    PhoneNumberPipe,
    HighlightPipe,
    RepeatFrequencyTextPipe,
    DurationPipe,
    SecondAsDurationPipe,
    TextInQuotesPipe,
    SearchTextPipe,
    HumanizedDatePipe,
    HumanizedDurationPipe,
    DateTimePipe,
    PbxDateTimePipe,
    FileSizePipe,
    InitialsPipe,
    ModulesFilterPipe,
    AuditTypePipe,
    YesNoPipe,
    EmailPipe,
    NameInitialsPipe,
    CardComponent,
    CardOptionsComponent,
    AudioComponent,
    AudioControlComponent,
    ExplanationComponent,
    DroppableDirective,
    TagComponent,
    TagsComponent,
    WeekDayPipe,
    LanguagePipe,
    WhatsAppStatusPipe,
    WhatsAppCategoryPipe,
    MacAddressPipe,
    ShowHideComponent,
    SmsContactsComponent,
    InViewComponent,
    InViewDirective,
    DynamicPageComponent,
    FilterRowAdditionalContentComponent,
    FilterSecondRowAdditionalContentComponent,
    WorkflowActionPanelComponent,
  ]
})
export class CoreModule {
}
