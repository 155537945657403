/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BooleanResponse } from '../models/boolean-response';
import { E911DetailsModel } from '../models/e-911-details-model';
import { PbxAccountGridResponse } from '../models/pbx-account-grid-response';
import { PbxAccountModel } from '../models/pbx-account-model';
import { pbxAccountsGetAccountDetails } from '../fn/pbx-accounts/pbx-accounts-get-account-details';
import { PbxAccountsGetAccountDetails$Params } from '../fn/pbx-accounts/pbx-accounts-get-account-details';
import { pbxAccountsGetE911Addresses } from '../fn/pbx-accounts/pbx-accounts-get-e-911-addresses';
import { PbxAccountsGetE911Addresses$Params } from '../fn/pbx-accounts/pbx-accounts-get-e-911-addresses';
import { pbxAccountsGetList } from '../fn/pbx-accounts/pbx-accounts-get-list';
import { PbxAccountsGetList$Params } from '../fn/pbx-accounts/pbx-accounts-get-list';
import { pbxAccountsRequestE911Addresses } from '../fn/pbx-accounts/pbx-accounts-request-e-911-addresses';
import { PbxAccountsRequestE911Addresses$Params } from '../fn/pbx-accounts/pbx-accounts-request-e-911-addresses';
import { pbxAccountsSaveAccountDetails } from '../fn/pbx-accounts/pbx-accounts-save-account-details';
import { PbxAccountsSaveAccountDetails$Params } from '../fn/pbx-accounts/pbx-accounts-save-account-details';

@Injectable({ providedIn: 'root' })
export class PbxAccountsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `pbxAccountsGetList()` */
  static readonly PbxAccountsGetListPath = '/api/pbx-accounts/list';

  /**
   * Get List Of Accounts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxAccountsGetList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxAccountsGetList$Response(params: PbxAccountsGetList$Params, context?: HttpContext): Observable<StrictHttpResponse<PbxAccountGridResponse>> {
    return pbxAccountsGetList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get List Of Accounts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxAccountsGetList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxAccountsGetList(params: PbxAccountsGetList$Params, context?: HttpContext): Observable<PbxAccountGridResponse> {
    return this.pbxAccountsGetList$Response(params, context).pipe(
      map((r: StrictHttpResponse<PbxAccountGridResponse>): PbxAccountGridResponse => r.body)
    );
  }

  /** Path part for operation `pbxAccountsGetAccountDetails()` */
  static readonly PbxAccountsGetAccountDetailsPath = '/api/pbx-accounts/get/{id}';

  /**
   * Get Account Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxAccountsGetAccountDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxAccountsGetAccountDetails$Response(params: PbxAccountsGetAccountDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<PbxAccountModel>> {
    return pbxAccountsGetAccountDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Account Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxAccountsGetAccountDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxAccountsGetAccountDetails(params: PbxAccountsGetAccountDetails$Params, context?: HttpContext): Observable<PbxAccountModel> {
    return this.pbxAccountsGetAccountDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<PbxAccountModel>): PbxAccountModel => r.body)
    );
  }

  /** Path part for operation `pbxAccountsSaveAccountDetails()` */
  static readonly PbxAccountsSaveAccountDetailsPath = '/api/pbx-accounts/save';

  /**
   * Save Account Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxAccountsSaveAccountDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxAccountsSaveAccountDetails$Response(params: PbxAccountsSaveAccountDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<PbxAccountModel>> {
    return pbxAccountsSaveAccountDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Save Account Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxAccountsSaveAccountDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxAccountsSaveAccountDetails(params: PbxAccountsSaveAccountDetails$Params, context?: HttpContext): Observable<PbxAccountModel> {
    return this.pbxAccountsSaveAccountDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<PbxAccountModel>): PbxAccountModel => r.body)
    );
  }

  /** Path part for operation `pbxAccountsGetE911Addresses()` */
  static readonly PbxAccountsGetE911AddressesPath = '/api/pbx-accounts/get-e911-addresses';

  /**
   * Get E911 addresses.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxAccountsGetE911Addresses()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxAccountsGetE911Addresses$Response(params?: PbxAccountsGetE911Addresses$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<E911DetailsModel>>> {
    return pbxAccountsGetE911Addresses(this.http, this.rootUrl, params, context);
  }

  /**
   * Get E911 addresses.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxAccountsGetE911Addresses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxAccountsGetE911Addresses(params?: PbxAccountsGetE911Addresses$Params, context?: HttpContext): Observable<Array<E911DetailsModel>> {
    return this.pbxAccountsGetE911Addresses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<E911DetailsModel>>): Array<E911DetailsModel> => r.body)
    );
  }

  /** Path part for operation `pbxAccountsRequestE911Addresses()` */
  static readonly PbxAccountsRequestE911AddressesPath = '/api/pbx-accounts/request-e911-address';

  /**
   * Request E911 address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxAccountsRequestE911Addresses()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxAccountsRequestE911Addresses$Response(params: PbxAccountsRequestE911Addresses$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanResponse>> {
    return pbxAccountsRequestE911Addresses(this.http, this.rootUrl, params, context);
  }

  /**
   * Request E911 address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxAccountsRequestE911Addresses$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxAccountsRequestE911Addresses(params: PbxAccountsRequestE911Addresses$Params, context?: HttpContext): Observable<BooleanResponse> {
    return this.pbxAccountsRequestE911Addresses$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanResponse>): BooleanResponse => r.body)
    );
  }

}
