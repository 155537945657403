/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { contactDeleteContactById } from '../fn/contact/contact-delete-contact-by-id';
import { ContactDeleteContactById$Params } from '../fn/contact/contact-delete-contact-by-id';
import { contactGetContactById } from '../fn/contact/contact-get-contact-by-id';
import { ContactGetContactById$Params } from '../fn/contact/contact-get-contact-by-id';
import { contactGetCustomFieldsDefinitions } from '../fn/contact/contact-get-custom-fields-definitions';
import { ContactGetCustomFieldsDefinitions$Params } from '../fn/contact/contact-get-custom-fields-definitions';
import { contactGetDuplicateUserContacts } from '../fn/contact/contact-get-duplicate-user-contacts';
import { ContactGetDuplicateUserContacts$Params } from '../fn/contact/contact-get-duplicate-user-contacts';
import { contactGetDuplicateUserContactsSummary } from '../fn/contact/contact-get-duplicate-user-contacts-summary';
import { ContactGetDuplicateUserContactsSummary$Params } from '../fn/contact/contact-get-duplicate-user-contacts-summary';
import { contactGetUserContacts } from '../fn/contact/contact-get-user-contacts';
import { ContactGetUserContacts$Params } from '../fn/contact/contact-get-user-contacts';
import { contactGetUserLookupContacts } from '../fn/contact/contact-get-user-lookup-contacts';
import { ContactGetUserLookupContacts$Params } from '../fn/contact/contact-get-user-lookup-contacts';
import { ContactModel } from '../models/contact-model';
import { contactSaveContact } from '../fn/contact/contact-save-contact';
import { ContactSaveContact$Params } from '../fn/contact/contact-save-contact';
import { contactSaveContacts } from '../fn/contact/contact-save-contacts';
import { ContactSaveContacts$Params } from '../fn/contact/contact-save-contacts';
import { ContactsResponse } from '../models/contacts-response';
import { CustomFieldModel } from '../models/custom-field-model';
import { DeleteContactResponse } from '../models/delete-contact-response';
import { DuplicateContactGridResponse } from '../models/duplicate-contact-grid-response';
import { DuplicateContactsSummaryResponse } from '../models/duplicate-contacts-summary-response';
import { LookupContactGridResponse } from '../models/lookup-contact-grid-response';

@Injectable({ providedIn: 'root' })
export class ContactsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `contactGetUserLookupContacts()` */
  static readonly ContactGetUserLookupContactsPath = '/api/contact/lookup';

  /**
   * Get List Of Lookup Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetUserLookupContacts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetUserLookupContacts$Response(params: ContactGetUserLookupContacts$Params, context?: HttpContext): Observable<StrictHttpResponse<LookupContactGridResponse>> {
    return contactGetUserLookupContacts(this.http, this.rootUrl, params, context);
  }

  /**
   * Get List Of Lookup Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetUserLookupContacts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetUserLookupContacts(params: ContactGetUserLookupContacts$Params, context?: HttpContext): Observable<LookupContactGridResponse> {
    return this.contactGetUserLookupContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupContactGridResponse>): LookupContactGridResponse => r.body)
    );
  }

  /** Path part for operation `contactGetUserContacts()` */
  static readonly ContactGetUserContactsPath = '/api/contact/list';

  /**
   * Get List Of Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetUserContacts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetUserContacts$Response(params: ContactGetUserContacts$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactsResponse>> {
    return contactGetUserContacts(this.http, this.rootUrl, params, context);
  }

  /**
   * Get List Of Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetUserContacts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetUserContacts(params: ContactGetUserContacts$Params, context?: HttpContext): Observable<ContactsResponse> {
    return this.contactGetUserContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactsResponse>): ContactsResponse => r.body)
    );
  }

  /** Path part for operation `contactGetContactById()` */
  static readonly ContactGetContactByIdPath = '/api/contact/contact/{id}';

  /**
   * Get get contact by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetContactById()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetContactById$Response(params: ContactGetContactById$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactModel>> {
    return contactGetContactById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get get contact by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetContactById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetContactById(params: ContactGetContactById$Params, context?: HttpContext): Observable<ContactModel> {
    return this.contactGetContactById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactModel>): ContactModel => r.body)
    );
  }

  /** Path part for operation `contactDeleteContactById()` */
  static readonly ContactDeleteContactByIdPath = '/api/contact/contact/{id}';

  /**
   * Delete contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactDeleteContactById()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactDeleteContactById$Response(params: ContactDeleteContactById$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteContactResponse>> {
    return contactDeleteContactById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactDeleteContactById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactDeleteContactById(params: ContactDeleteContactById$Params, context?: HttpContext): Observable<DeleteContactResponse> {
    return this.contactDeleteContactById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteContactResponse>): DeleteContactResponse => r.body)
    );
  }

  /** Path part for operation `contactSaveContact()` */
  static readonly ContactSaveContactPath = '/api/contact/save';

  /**
   * Save contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSaveContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSaveContact$Response(params: ContactSaveContact$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactModel>> {
    return contactSaveContact(this.http, this.rootUrl, params, context);
  }

  /**
   * Save contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSaveContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSaveContact(params: ContactSaveContact$Params, context?: HttpContext): Observable<ContactModel> {
    return this.contactSaveContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactModel>): ContactModel => r.body)
    );
  }

  /** Path part for operation `contactSaveContacts()` */
  static readonly ContactSaveContactsPath = '/api/contact/save-contacts';

  /**
   * Save contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSaveContacts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSaveContacts$Response(params: ContactSaveContacts$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return contactSaveContacts(this.http, this.rootUrl, params, context);
  }

  /**
   * Save contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSaveContacts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSaveContacts(params: ContactSaveContacts$Params, context?: HttpContext): Observable<void> {
    return this.contactSaveContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `contactGetDuplicateUserContactsSummary()` */
  static readonly ContactGetDuplicateUserContactsSummaryPath = '/api/contact/duplicate-summary';

  /**
   * Get Summary Of Duplicate Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetDuplicateUserContactsSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetDuplicateUserContactsSummary$Response(params?: ContactGetDuplicateUserContactsSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<DuplicateContactsSummaryResponse>> {
    return contactGetDuplicateUserContactsSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Summary Of Duplicate Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetDuplicateUserContactsSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetDuplicateUserContactsSummary(params?: ContactGetDuplicateUserContactsSummary$Params, context?: HttpContext): Observable<DuplicateContactsSummaryResponse> {
    return this.contactGetDuplicateUserContactsSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<DuplicateContactsSummaryResponse>): DuplicateContactsSummaryResponse => r.body)
    );
  }

  /** Path part for operation `contactGetDuplicateUserContacts()` */
  static readonly ContactGetDuplicateUserContactsPath = '/api/contact/duplicate-list';

  /**
   * Get List Of Duplicate Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetDuplicateUserContacts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetDuplicateUserContacts$Response(params: ContactGetDuplicateUserContacts$Params, context?: HttpContext): Observable<StrictHttpResponse<DuplicateContactGridResponse>> {
    return contactGetDuplicateUserContacts(this.http, this.rootUrl, params, context);
  }

  /**
   * Get List Of Duplicate Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetDuplicateUserContacts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetDuplicateUserContacts(params: ContactGetDuplicateUserContacts$Params, context?: HttpContext): Observable<DuplicateContactGridResponse> {
    return this.contactGetDuplicateUserContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<DuplicateContactGridResponse>): DuplicateContactGridResponse => r.body)
    );
  }

  /** Path part for operation `contactGetCustomFieldsDefinitions()` */
  static readonly ContactGetCustomFieldsDefinitionsPath = '/api/contact/custom-fields';

  /**
   * Get List Custom Fields.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetCustomFieldsDefinitions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetCustomFieldsDefinitions$Response(params: ContactGetCustomFieldsDefinitions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomFieldModel>>> {
    return contactGetCustomFieldsDefinitions(this.http, this.rootUrl, params, context);
  }

  /**
   * Get List Custom Fields.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetCustomFieldsDefinitions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetCustomFieldsDefinitions(params: ContactGetCustomFieldsDefinitions$Params, context?: HttpContext): Observable<Array<CustomFieldModel>> {
    return this.contactGetCustomFieldsDefinitions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomFieldModel>>): Array<CustomFieldModel> => r.body)
    );
  }

}
