/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { authenticationGoogleCallback } from '../fn/auth/authentication-google-callback';
import { AuthenticationGoogleCallback$Params } from '../fn/auth/authentication-google-callback';
import { authenticationGoogleLogin } from '../fn/auth/authentication-google-login';
import { AuthenticationGoogleLogin$Params } from '../fn/auth/authentication-google-login';
import { authenticationLogin } from '../fn/auth/authentication-login';
import { AuthenticationLogin$Params } from '../fn/auth/authentication-login';
import { authenticationLoginWithSoftphone } from '../fn/auth/authentication-login-with-softphone';
import { AuthenticationLoginWithSoftphone$Params } from '../fn/auth/authentication-login-with-softphone';
import { authenticationLoginWithToken } from '../fn/auth/authentication-login-with-token';
import { AuthenticationLoginWithToken$Params } from '../fn/auth/authentication-login-with-token';
import { authenticationLogout } from '../fn/auth/authentication-logout';
import { AuthenticationLogout$Params } from '../fn/auth/authentication-logout';
import { authenticationResendOtp } from '../fn/auth/authentication-resend-otp';
import { AuthenticationResendOtp$Params } from '../fn/auth/authentication-resend-otp';
import { authenticationResetPassword } from '../fn/auth/authentication-reset-password';
import { AuthenticationResetPassword$Params } from '../fn/auth/authentication-reset-password';
import { authenticationResetPasswordConfirm } from '../fn/auth/authentication-reset-password-confirm';
import { AuthenticationResetPasswordConfirm$Params } from '../fn/auth/authentication-reset-password-confirm';
import { authenticationSwitchCompany } from '../fn/auth/authentication-switch-company';
import { AuthenticationSwitchCompany$Params } from '../fn/auth/authentication-switch-company';
import { authenticationVerifyOtp } from '../fn/auth/authentication-verify-otp';
import { AuthenticationVerifyOtp$Params } from '../fn/auth/authentication-verify-otp';
import { CurrentUser } from '../models/current-user';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `authenticationLoginWithToken()` */
  static readonly AuthenticationLoginWithTokenPath = '/api/auth/login';

  /**
   * Single click login.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationLoginWithToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationLoginWithToken$Response(params: AuthenticationLoginWithToken$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authenticationLoginWithToken(this.http, this.rootUrl, params, context);
  }

  /**
   * Single click login.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationLoginWithToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationLoginWithToken(params: AuthenticationLoginWithToken$Params, context?: HttpContext): Observable<void> {
    return this.authenticationLoginWithToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authenticationLogin()` */
  static readonly AuthenticationLoginPath = '/api/auth/login';

  /**
   * Login using local authentication strategy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationLogin$Response(params: AuthenticationLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<CurrentUser>> {
    return authenticationLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * Login using local authentication strategy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationLogin(params: AuthenticationLogin$Params, context?: HttpContext): Observable<CurrentUser> {
    return this.authenticationLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrentUser>): CurrentUser => r.body)
    );
  }

  /** Path part for operation `authenticationLoginWithSoftphone()` */
  static readonly AuthenticationLoginWithSoftphonePath = '/api/auth/softphone-login';

  /**
   * Login to portal from softphone.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationLoginWithSoftphone()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationLoginWithSoftphone$Response(params: AuthenticationLoginWithSoftphone$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authenticationLoginWithSoftphone(this.http, this.rootUrl, params, context);
  }

  /**
   * Login to portal from softphone.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationLoginWithSoftphone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationLoginWithSoftphone(params: AuthenticationLoginWithSoftphone$Params, context?: HttpContext): Observable<void> {
    return this.authenticationLoginWithSoftphone$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authenticationVerifyOtp()` */
  static readonly AuthenticationVerifyOtpPath = '/api/auth/verify-otp';

  /**
   * Login using local authentication strategy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationVerifyOtp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationVerifyOtp$Response(params: AuthenticationVerifyOtp$Params, context?: HttpContext): Observable<StrictHttpResponse<CurrentUser>> {
    return authenticationVerifyOtp(this.http, this.rootUrl, params, context);
  }

  /**
   * Login using local authentication strategy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationVerifyOtp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationVerifyOtp(params: AuthenticationVerifyOtp$Params, context?: HttpContext): Observable<CurrentUser> {
    return this.authenticationVerifyOtp$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrentUser>): CurrentUser => r.body)
    );
  }

  /** Path part for operation `authenticationResendOtp()` */
  static readonly AuthenticationResendOtpPath = '/api/auth/resend-otp';

  /**
   * Resend OTP to phone number.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationResendOtp()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationResendOtp$Response(params?: AuthenticationResendOtp$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return authenticationResendOtp(this.http, this.rootUrl, params, context);
  }

  /**
   * Resend OTP to phone number.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationResendOtp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationResendOtp(params?: AuthenticationResendOtp$Params, context?: HttpContext): Observable<string> {
    return this.authenticationResendOtp$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `authenticationLogout()` */
  static readonly AuthenticationLogoutPath = '/api/auth/logout';

  /**
   * Login using local authentication strategy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationLogout$Response(params?: AuthenticationLogout$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authenticationLogout(this.http, this.rootUrl, params, context);
  }

  /**
   * Login using local authentication strategy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationLogout(params?: AuthenticationLogout$Params, context?: HttpContext): Observable<void> {
    return this.authenticationLogout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authenticationResetPasswordConfirm()` */
  static readonly AuthenticationResetPasswordConfirmPath = '/api/auth/reset-password';

  /**
   * Submit new password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationResetPasswordConfirm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationResetPasswordConfirm$Response(params: AuthenticationResetPasswordConfirm$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authenticationResetPasswordConfirm(this.http, this.rootUrl, params, context);
  }

  /**
   * Submit new password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationResetPasswordConfirm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationResetPasswordConfirm(params: AuthenticationResetPasswordConfirm$Params, context?: HttpContext): Observable<void> {
    return this.authenticationResetPasswordConfirm$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authenticationResetPassword()` */
  static readonly AuthenticationResetPasswordPath = '/api/auth/reset-password';

  /**
   * Send reset password email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationResetPassword()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  authenticationResetPassword$Response(params: AuthenticationResetPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authenticationResetPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Send reset password email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationResetPassword$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  authenticationResetPassword(params: AuthenticationResetPassword$Params, context?: HttpContext): Observable<void> {
    return this.authenticationResetPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authenticationGoogleLogin()` */
  static readonly AuthenticationGoogleLoginPath = '/api/auth/google/login';

  /**
   * Login using google oauth2.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationGoogleLogin()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationGoogleLogin$Response(params?: AuthenticationGoogleLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authenticationGoogleLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * Login using google oauth2.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationGoogleLogin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationGoogleLogin(params?: AuthenticationGoogleLogin$Params, context?: HttpContext): Observable<void> {
    return this.authenticationGoogleLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authenticationGoogleCallback()` */
  static readonly AuthenticationGoogleCallbackPath = '/api/auth/google/callback';

  /**
   * Login using google authentication strategy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationGoogleCallback()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationGoogleCallback$Response(params?: AuthenticationGoogleCallback$Params, context?: HttpContext): Observable<StrictHttpResponse<CurrentUser>> {
    return authenticationGoogleCallback(this.http, this.rootUrl, params, context);
  }

  /**
   * Login using google authentication strategy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationGoogleCallback$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationGoogleCallback(params?: AuthenticationGoogleCallback$Params, context?: HttpContext): Observable<CurrentUser> {
    return this.authenticationGoogleCallback$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrentUser>): CurrentUser => r.body)
    );
  }

  /** Path part for operation `authenticationSwitchCompany()` */
  static readonly AuthenticationSwitchCompanyPath = '/api/auth/switch-company';

  /**
   * Login using google authentication strategy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationSwitchCompany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationSwitchCompany$Response(params: AuthenticationSwitchCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<CurrentUser>> {
    return authenticationSwitchCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Login using google authentication strategy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationSwitchCompany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationSwitchCompany(params: AuthenticationSwitchCompany$Params, context?: HttpContext): Observable<CurrentUser> {
    return this.authenticationSwitchCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrentUser>): CurrentUser => r.body)
    );
  }

}
