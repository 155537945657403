/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { UsersSummaryResponse } from '../models/users-summary-response';
import { usersSummaryV2GetExport } from '../fn/reports-v-2/users-summary-v-2-get-export';
import { UsersSummaryV2GetExport$Params } from '../fn/reports-v-2/users-summary-v-2-get-export';
import { usersSummaryV2GetSummaryData } from '../fn/reports-v-2/users-summary-v-2-get-summary-data';
import { UsersSummaryV2GetSummaryData$Params } from '../fn/reports-v-2/users-summary-v-2-get-summary-data';

@Injectable({ providedIn: 'root' })
export class UsersReportsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `usersSummaryV2GetSummaryData()` */
  static readonly UsersSummaryV2GetSummaryDataPath = '/api/reports-v2/users-summary';

  /**
   * Get users dashboard data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSummaryV2GetSummaryData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryV2GetSummaryData$Response(params: UsersSummaryV2GetSummaryData$Params, context?: HttpContext): Observable<StrictHttpResponse<UsersSummaryResponse>> {
    return usersSummaryV2GetSummaryData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get users dashboard data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersSummaryV2GetSummaryData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryV2GetSummaryData(params: UsersSummaryV2GetSummaryData$Params, context?: HttpContext): Observable<UsersSummaryResponse> {
    return this.usersSummaryV2GetSummaryData$Response(params, context).pipe(
      map((r: StrictHttpResponse<UsersSummaryResponse>): UsersSummaryResponse => r.body)
    );
  }

  /** Path part for operation `usersSummaryV2GetExport()` */
  static readonly UsersSummaryV2GetExportPath = '/api/reports-v2/users-summary/export';

  /**
   * Trigger custom reports scheduler.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSummaryV2GetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryV2GetExport$Response(params: UsersSummaryV2GetExport$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return usersSummaryV2GetExport(this.http, this.rootUrl, params, context);
  }

  /**
   * Trigger custom reports scheduler.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersSummaryV2GetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryV2GetExport(params: UsersSummaryV2GetExport$Params, context?: HttpContext): Observable<string> {
    return this.usersSummaryV2GetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
