/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CallLogModel } from '../models/call-log-model';
import { CallLogsResponse } from '../models/call-logs-response';
import { callLogsV2GetCallFlowDetails } from '../fn/reports-v-2/call-logs-v-2-get-call-flow-details';
import { CallLogsV2GetCallFlowDetails$Params } from '../fn/reports-v-2/call-logs-v-2-get-call-flow-details';
import { callLogsV2GetCallLogs } from '../fn/reports-v-2/call-logs-v-2-get-call-logs';
import { CallLogsV2GetCallLogs$Params } from '../fn/reports-v-2/call-logs-v-2-get-call-logs';
import { callLogsV2GetExport } from '../fn/reports-v-2/call-logs-v-2-get-export';
import { CallLogsV2GetExport$Params } from '../fn/reports-v-2/call-logs-v-2-get-export';
import { callLogsV2GetRawCallDetails } from '../fn/reports-v-2/call-logs-v-2-get-raw-call-details';
import { CallLogsV2GetRawCallDetails$Params } from '../fn/reports-v-2/call-logs-v-2-get-raw-call-details';
import { RawCallModel } from '../models/raw-call-model';

@Injectable({ providedIn: 'root' })
export class CallLogsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `callLogsV2GetCallLogs()` */
  static readonly CallLogsV2GetCallLogsPath = '/api/reports-v2/call-logs';

  /**
   * Get Call Logs data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsV2GetCallLogs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetCallLogs$Response(params: CallLogsV2GetCallLogs$Params, context?: HttpContext): Observable<StrictHttpResponse<CallLogsResponse>> {
    return callLogsV2GetCallLogs(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Call Logs data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsV2GetCallLogs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetCallLogs(params: CallLogsV2GetCallLogs$Params, context?: HttpContext): Observable<CallLogsResponse> {
    return this.callLogsV2GetCallLogs$Response(params, context).pipe(
      map((r: StrictHttpResponse<CallLogsResponse>): CallLogsResponse => r.body)
    );
  }

  /** Path part for operation `callLogsV2GetExport()` */
  static readonly CallLogsV2GetExportPath = '/api/reports-v2/call-logs/export';

  /**
   * Add Export Job to queue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsV2GetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetExport$Response(params: CallLogsV2GetExport$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return callLogsV2GetExport(this.http, this.rootUrl, params, context);
  }

  /**
   * Add Export Job to queue.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsV2GetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetExport(params: CallLogsV2GetExport$Params, context?: HttpContext): Observable<string> {
    return this.callLogsV2GetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `callLogsV2GetRawCallDetails()` */
  static readonly CallLogsV2GetRawCallDetailsPath = '/api/reports-v2/raw-call-details';

  /**
   * Get Raw Call Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsV2GetRawCallDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetRawCallDetails$Response(params: CallLogsV2GetRawCallDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<RawCallModel>> {
    return callLogsV2GetRawCallDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Raw Call Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsV2GetRawCallDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetRawCallDetails(params: CallLogsV2GetRawCallDetails$Params, context?: HttpContext): Observable<RawCallModel> {
    return this.callLogsV2GetRawCallDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<RawCallModel>): RawCallModel => r.body)
    );
  }

  /** Path part for operation `callLogsV2GetCallFlowDetails()` */
  static readonly CallLogsV2GetCallFlowDetailsPath = '/api/reports-v2/call-flow-detail';

  /**
   * Get Call Flow Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsV2GetCallFlowDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetCallFlowDetails$Response(params: CallLogsV2GetCallFlowDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<CallLogModel>> {
    return callLogsV2GetCallFlowDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Call Flow Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsV2GetCallFlowDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetCallFlowDetails(params: CallLogsV2GetCallFlowDetails$Params, context?: HttpContext): Observable<CallLogModel> {
    return this.callLogsV2GetCallFlowDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<CallLogModel>): CallLogModel => r.body)
    );
  }

}
