/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BooleanResponse } from '../models/boolean-response';
import { DeleteEventsFlowByIdResponse } from '../models/delete-events-flow-by-id-response';
import { EventsFlowByIdResponse } from '../models/events-flow-by-id-response';
import { eventsFlowDeleteEventsFlowById } from '../fn/events-flow/events-flow-delete-events-flow-by-id';
import { EventsFlowDeleteEventsFlowById$Params } from '../fn/events-flow/events-flow-delete-events-flow-by-id';
import { eventsFlowEnable } from '../fn/events-flow/events-flow-enable';
import { EventsFlowEnable$Params } from '../fn/events-flow/events-flow-enable';
import { eventsFlowGetCaregiverNoteSubjects } from '../fn/events-flow/events-flow-get-caregiver-note-subjects';
import { EventsFlowGetCaregiverNoteSubjects$Params } from '../fn/events-flow/events-flow-get-caregiver-note-subjects';
import { eventsFlowGetEventsFlowById } from '../fn/events-flow/events-flow-get-events-flow-by-id';
import { EventsFlowGetEventsFlowById$Params } from '../fn/events-flow/events-flow-get-events-flow-by-id';
import { eventsFlowGetEventsFlowList } from '../fn/events-flow/events-flow-get-events-flow-list';
import { EventsFlowGetEventsFlowList$Params } from '../fn/events-flow/events-flow-get-events-flow-list';
import { eventsFlowGetPatientNoteReasons } from '../fn/events-flow/events-flow-get-patient-note-reasons';
import { EventsFlowGetPatientNoteReasons$Params } from '../fn/events-flow/events-flow-get-patient-note-reasons';
import { eventsFlowSaveEventsFlow } from '../fn/events-flow/events-flow-save-events-flow';
import { EventsFlowSaveEventsFlow$Params } from '../fn/events-flow/events-flow-save-events-flow';
import { GetEventsFlowListResponse } from '../models/get-events-flow-list-response';
import { LookupModel } from '../models/lookup-model';

@Injectable({ providedIn: 'root' })
export class EventsFlowService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `eventsFlowGetEventsFlowList()` */
  static readonly EventsFlowGetEventsFlowListPath = '/api/events-flow/list';

  /**
   * List all events flows.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsFlowGetEventsFlowList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsFlowGetEventsFlowList$Response(params: EventsFlowGetEventsFlowList$Params, context?: HttpContext): Observable<StrictHttpResponse<GetEventsFlowListResponse>> {
    return eventsFlowGetEventsFlowList(this.http, this.rootUrl, params, context);
  }

  /**
   * List all events flows.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsFlowGetEventsFlowList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsFlowGetEventsFlowList(params: EventsFlowGetEventsFlowList$Params, context?: HttpContext): Observable<GetEventsFlowListResponse> {
    return this.eventsFlowGetEventsFlowList$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetEventsFlowListResponse>): GetEventsFlowListResponse => r.body)
    );
  }

  /** Path part for operation `eventsFlowGetEventsFlowById()` */
  static readonly EventsFlowGetEventsFlowByIdPath = '/api/events-flow/{id}';

  /**
   * Get Events Flow by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsFlowGetEventsFlowById()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsFlowGetEventsFlowById$Response(params: EventsFlowGetEventsFlowById$Params, context?: HttpContext): Observable<StrictHttpResponse<EventsFlowByIdResponse>> {
    return eventsFlowGetEventsFlowById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Events Flow by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsFlowGetEventsFlowById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsFlowGetEventsFlowById(params: EventsFlowGetEventsFlowById$Params, context?: HttpContext): Observable<EventsFlowByIdResponse> {
    return this.eventsFlowGetEventsFlowById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventsFlowByIdResponse>): EventsFlowByIdResponse => r.body)
    );
  }

  /** Path part for operation `eventsFlowDeleteEventsFlowById()` */
  static readonly EventsFlowDeleteEventsFlowByIdPath = '/api/events-flow/{id}';

  /**
   * Delete Events Flow by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsFlowDeleteEventsFlowById()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsFlowDeleteEventsFlowById$Response(params: EventsFlowDeleteEventsFlowById$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteEventsFlowByIdResponse>> {
    return eventsFlowDeleteEventsFlowById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Events Flow by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsFlowDeleteEventsFlowById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsFlowDeleteEventsFlowById(params: EventsFlowDeleteEventsFlowById$Params, context?: HttpContext): Observable<DeleteEventsFlowByIdResponse> {
    return this.eventsFlowDeleteEventsFlowById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteEventsFlowByIdResponse>): DeleteEventsFlowByIdResponse => r.body)
    );
  }

  /** Path part for operation `eventsFlowEnable()` */
  static readonly EventsFlowEnablePath = '/api/events-flow/enable';

  /**
   * Enable events flow.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsFlowEnable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsFlowEnable$Response(params: EventsFlowEnable$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanResponse>> {
    return eventsFlowEnable(this.http, this.rootUrl, params, context);
  }

  /**
   * Enable events flow.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsFlowEnable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsFlowEnable(params: EventsFlowEnable$Params, context?: HttpContext): Observable<BooleanResponse> {
    return this.eventsFlowEnable$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanResponse>): BooleanResponse => r.body)
    );
  }

  /** Path part for operation `eventsFlowSaveEventsFlow()` */
  static readonly EventsFlowSaveEventsFlowPath = '/api/events-flow/save';

  /**
   * Save events flow.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsFlowSaveEventsFlow()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsFlowSaveEventsFlow$Response(params: EventsFlowSaveEventsFlow$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanResponse>> {
    return eventsFlowSaveEventsFlow(this.http, this.rootUrl, params, context);
  }

  /**
   * Save events flow.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsFlowSaveEventsFlow$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsFlowSaveEventsFlow(params: EventsFlowSaveEventsFlow$Params, context?: HttpContext): Observable<BooleanResponse> {
    return this.eventsFlowSaveEventsFlow$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanResponse>): BooleanResponse => r.body)
    );
  }

  /** Path part for operation `eventsFlowGetCaregiverNoteSubjects()` */
  static readonly EventsFlowGetCaregiverNoteSubjectsPath = '/api/events-flow/caregiver-note-subjects';

  /**
   * Get Caregiver Note Subjects.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsFlowGetCaregiverNoteSubjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsFlowGetCaregiverNoteSubjects$Response(params?: EventsFlowGetCaregiverNoteSubjects$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LookupModel>>> {
    return eventsFlowGetCaregiverNoteSubjects(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Caregiver Note Subjects.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsFlowGetCaregiverNoteSubjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsFlowGetCaregiverNoteSubjects(params?: EventsFlowGetCaregiverNoteSubjects$Params, context?: HttpContext): Observable<Array<LookupModel>> {
    return this.eventsFlowGetCaregiverNoteSubjects$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LookupModel>>): Array<LookupModel> => r.body)
    );
  }

  /** Path part for operation `eventsFlowGetPatientNoteReasons()` */
  static readonly EventsFlowGetPatientNoteReasonsPath = '/api/events-flow/patient-note-reasons';

  /**
   * Get Patient Note Reasons.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsFlowGetPatientNoteReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsFlowGetPatientNoteReasons$Response(params?: EventsFlowGetPatientNoteReasons$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LookupModel>>> {
    return eventsFlowGetPatientNoteReasons(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Patient Note Reasons.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsFlowGetPatientNoteReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsFlowGetPatientNoteReasons(params?: EventsFlowGetPatientNoteReasons$Params, context?: HttpContext): Observable<Array<LookupModel>> {
    return this.eventsFlowGetPatientNoteReasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LookupModel>>): Array<LookupModel> => r.body)
    );
  }

}
