/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ModuleDefinitionModel } from '../models/module-definition-model';
import { systemCompose } from '../fn/system/system-compose';
import { SystemCompose$Params } from '../fn/system/system-compose';
import { systemCustomerPackageChangeNotification } from '../fn/system/system-customer-package-change-notification';
import { SystemCustomerPackageChangeNotification$Params } from '../fn/system/system-customer-package-change-notification';
import { systemDisableSms } from '../fn/system/system-disable-sms';
import { SystemDisableSms$Params } from '../fn/system/system-disable-sms';
import { systemDisableSmsPro } from '../fn/system/system-disable-sms-pro';
import { SystemDisableSmsPro$Params } from '../fn/system/system-disable-sms-pro';
import { systemGetModuleList } from '../fn/system/system-get-module-list';
import { SystemGetModuleList$Params } from '../fn/system/system-get-module-list';
import { systemPackageChangeNotification } from '../fn/system/system-package-change-notification';
import { SystemPackageChangeNotification$Params } from '../fn/system/system-package-change-notification';
import { systemRecoverFileByCustomerId } from '../fn/system/system-recover-file-by-customer-id';
import { SystemRecoverFileByCustomerId$Params } from '../fn/system/system-recover-file-by-customer-id';
import { systemRecoverFileById } from '../fn/system/system-recover-file-by-id';
import { SystemRecoverFileById$Params } from '../fn/system/system-recover-file-by-id';
import { systemRefreshPbxAccounts } from '../fn/system/system-refresh-pbx-accounts';
import { SystemRefreshPbxAccounts$Params } from '../fn/system/system-refresh-pbx-accounts';
import { systemReprovisionAcrobits } from '../fn/system/system-reprovision-acrobits';
import { SystemReprovisionAcrobits$Params } from '../fn/system/system-reprovision-acrobits';
import { systemSendCounterPathLicenseEmail } from '../fn/system/system-send-counter-path-license-email';
import { SystemSendCounterPathLicenseEmail$Params } from '../fn/system/system-send-counter-path-license-email';
import { systemSendUserInvitation } from '../fn/system/system-send-user-invitation';
import { SystemSendUserInvitation$Params } from '../fn/system/system-send-user-invitation';
import { systemSyncAllCustomersPbx } from '../fn/system/system-sync-all-customers-pbx';
import { SystemSyncAllCustomersPbx$Params } from '../fn/system/system-sync-all-customers-pbx';
import { systemSyncBrands } from '../fn/system/system-sync-brands';
import { SystemSyncBrands$Params } from '../fn/system/system-sync-brands';
import { systemSyncCampaigns } from '../fn/system/system-sync-campaigns';
import { SystemSyncCampaigns$Params } from '../fn/system/system-sync-campaigns';
import { systemSyncCampaignsStatus } from '../fn/system/system-sync-campaigns-status';
import { SystemSyncCampaignsStatus$Params } from '../fn/system/system-sync-campaigns-status';
import { systemSyncCustomerPbx } from '../fn/system/system-sync-customer-pbx';
import { SystemSyncCustomerPbx$Params } from '../fn/system/system-sync-customer-pbx';
import { systemSyncCustomerPbxAccount } from '../fn/system/system-sync-customer-pbx-account';
import { SystemSyncCustomerPbxAccount$Params } from '../fn/system/system-sync-customer-pbx-account';
import { systemSyncCustomerPbxAccounts } from '../fn/system/system-sync-customer-pbx-accounts';
import { SystemSyncCustomerPbxAccounts$Params } from '../fn/system/system-sync-customer-pbx-accounts';
import { systemSyncPendingPhoneNumberCampaigns } from '../fn/system/system-sync-pending-phone-number-campaigns';
import { SystemSyncPendingPhoneNumberCampaigns$Params } from '../fn/system/system-sync-pending-phone-number-campaigns';
import { systemSyncPhoneNumberCampaigns } from '../fn/system/system-sync-phone-number-campaigns';
import { SystemSyncPhoneNumberCampaigns$Params } from '../fn/system/system-sync-phone-number-campaigns';
import { systemUpdateFaxDomainSettings } from '../fn/system/system-update-fax-domain-settings';
import { SystemUpdateFaxDomainSettings$Params } from '../fn/system/system-update-fax-domain-settings';
import { systemUpdatePrimaryRoles } from '../fn/system/system-update-primary-roles';
import { SystemUpdatePrimaryRoles$Params } from '../fn/system/system-update-primary-roles';
import { systemUpdateSms } from '../fn/system/system-update-sms';
import { SystemUpdateSms$Params } from '../fn/system/system-update-sms';
import { systemUpdateUsersDependencies } from '../fn/system/system-update-users-dependencies';
import { SystemUpdateUsersDependencies$Params } from '../fn/system/system-update-users-dependencies';
import { systemUpdateUsersSessions } from '../fn/system/system-update-users-sessions';
import { SystemUpdateUsersSessions$Params } from '../fn/system/system-update-users-sessions';

@Injectable({ providedIn: 'root' })
export class SystemService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `systemSendUserInvitation()` */
  static readonly SystemSendUserInvitationPath = '/api/system/send-user-invitation';

  /**
   * Send invitation email to user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSendUserInvitation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSendUserInvitation$Response(params: SystemSendUserInvitation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemSendUserInvitation(this.http, this.rootUrl, params, context);
  }

  /**
   * Send invitation email to user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSendUserInvitation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSendUserInvitation(params: SystemSendUserInvitation$Params, context?: HttpContext): Observable<void> {
    return this.systemSendUserInvitation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemGetModuleList()` */
  static readonly SystemGetModuleListPath = '/api/system/module-list';

  /**
   * Get list of all modules.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemGetModuleList()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemGetModuleList$Response(params?: SystemGetModuleList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ModuleDefinitionModel>>> {
    return systemGetModuleList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of all modules.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemGetModuleList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemGetModuleList(params?: SystemGetModuleList$Params, context?: HttpContext): Observable<Array<ModuleDefinitionModel>> {
    return this.systemGetModuleList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ModuleDefinitionModel>>): Array<ModuleDefinitionModel> => r.body)
    );
  }

  /** Path part for operation `systemUpdatePrimaryRoles()` */
  static readonly SystemUpdatePrimaryRolesPath = '/api/system/update-roles';

  /**
   * Update system roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemUpdatePrimaryRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemUpdatePrimaryRoles$Response(params?: SystemUpdatePrimaryRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return systemUpdatePrimaryRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * Update system roles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemUpdatePrimaryRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemUpdatePrimaryRoles(params?: SystemUpdatePrimaryRoles$Params, context?: HttpContext): Observable<boolean> {
    return this.systemUpdatePrimaryRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `systemRecoverFileById()` */
  static readonly SystemRecoverFileByIdPath = '/api/system/recover-recording/{id}';

  /**
   * Restore Recording By Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemRecoverFileById()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemRecoverFileById$Response(params: SystemRecoverFileById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemRecoverFileById(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore Recording By Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemRecoverFileById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemRecoverFileById(params: SystemRecoverFileById$Params, context?: HttpContext): Observable<void> {
    return this.systemRecoverFileById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemRecoverFileByCustomerId()` */
  static readonly SystemRecoverFileByCustomerIdPath = '/api/system/recover-recording/customer/{id}';

  /**
   * Restore Recording By CustomerId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemRecoverFileByCustomerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemRecoverFileByCustomerId$Response(params: SystemRecoverFileByCustomerId$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemRecoverFileByCustomerId(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore Recording By CustomerId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemRecoverFileByCustomerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemRecoverFileByCustomerId(params: SystemRecoverFileByCustomerId$Params, context?: HttpContext): Observable<void> {
    return this.systemRecoverFileByCustomerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemDisableSmsPro()` */
  static readonly SystemDisableSmsProPath = '/api/system/disable-sms-pro/{id}';

  /**
   * Disable sms pro and remove sms users from phone number.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemDisableSmsPro()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemDisableSmsPro$Response(params: SystemDisableSmsPro$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemDisableSmsPro(this.http, this.rootUrl, params, context);
  }

  /**
   * Disable sms pro and remove sms users from phone number.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemDisableSmsPro$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemDisableSmsPro(params: SystemDisableSmsPro$Params, context?: HttpContext): Observable<void> {
    return this.systemDisableSmsPro$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemDisableSms()` */
  static readonly SystemDisableSmsPath = '/api/system/disable-sms/{id}';

  /**
   * Disable sms and remove sms users from phone number.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemDisableSms()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemDisableSms$Response(params: SystemDisableSms$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemDisableSms(this.http, this.rootUrl, params, context);
  }

  /**
   * Disable sms and remove sms users from phone number.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemDisableSms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemDisableSms(params: SystemDisableSms$Params, context?: HttpContext): Observable<void> {
    return this.systemDisableSms$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemUpdateSms()` */
  static readonly SystemUpdateSmsPath = '/api/system/update-sms/{id}';

  /**
   * Update sms and Campaign ID on Bandwidth.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemUpdateSms()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemUpdateSms$Response(params: SystemUpdateSms$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemUpdateSms(this.http, this.rootUrl, params, context);
  }

  /**
   * Update sms and Campaign ID on Bandwidth.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemUpdateSms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemUpdateSms(params: SystemUpdateSms$Params, context?: HttpContext): Observable<void> {
    return this.systemUpdateSms$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemCustomerPackageChangeNotification()` */
  static readonly SystemCustomerPackageChangeNotificationPath = '/api/system/customer-package-change-notification';

  /**
   * Notify Billing if CP customer package was changed.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemCustomerPackageChangeNotification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemCustomerPackageChangeNotification$Response(params: SystemCustomerPackageChangeNotification$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemCustomerPackageChangeNotification(this.http, this.rootUrl, params, context);
  }

  /**
   * Notify Billing if CP customer package was changed.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemCustomerPackageChangeNotification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemCustomerPackageChangeNotification(params: SystemCustomerPackageChangeNotification$Params, context?: HttpContext): Observable<void> {
    return this.systemCustomerPackageChangeNotification$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemPackageChangeNotification()` */
  static readonly SystemPackageChangeNotificationPath = '/api/system/package-change-notification';

  /**
   * Notify Billing if CP customer package was changed.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemPackageChangeNotification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemPackageChangeNotification$Response(params: SystemPackageChangeNotification$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemPackageChangeNotification(this.http, this.rootUrl, params, context);
  }

  /**
   * Notify Billing if CP customer package was changed.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemPackageChangeNotification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemPackageChangeNotification(params: SystemPackageChangeNotification$Params, context?: HttpContext): Observable<void> {
    return this.systemPackageChangeNotification$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemUpdateUsersSessions()` */
  static readonly SystemUpdateUsersSessionsPath = '/api/system/update-users-sessions';

  /**
   * Update active user sessions with new changes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemUpdateUsersSessions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemUpdateUsersSessions$Response(params: SystemUpdateUsersSessions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemUpdateUsersSessions(this.http, this.rootUrl, params, context);
  }

  /**
   * Update active user sessions with new changes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemUpdateUsersSessions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemUpdateUsersSessions(params: SystemUpdateUsersSessions$Params, context?: HttpContext): Observable<void> {
    return this.systemUpdateUsersSessions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemUpdateUsersDependencies()` */
  static readonly SystemUpdateUsersDependenciesPath = '/api/system/update-user-dependencies';

  /**
   * Update active user sessions with new changes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemUpdateUsersDependencies()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemUpdateUsersDependencies$Response(params: SystemUpdateUsersDependencies$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemUpdateUsersDependencies(this.http, this.rootUrl, params, context);
  }

  /**
   * Update active user sessions with new changes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemUpdateUsersDependencies$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemUpdateUsersDependencies(params: SystemUpdateUsersDependencies$Params, context?: HttpContext): Observable<void> {
    return this.systemUpdateUsersDependencies$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemUpdateFaxDomainSettings()` */
  static readonly SystemUpdateFaxDomainSettingsPath = '/api/system/update-fax-domain-settings';

  /**
   * Update fax domain settings if user was updated.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemUpdateFaxDomainSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemUpdateFaxDomainSettings$Response(params: SystemUpdateFaxDomainSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemUpdateFaxDomainSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Update fax domain settings if user was updated.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemUpdateFaxDomainSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemUpdateFaxDomainSettings(params: SystemUpdateFaxDomainSettings$Params, context?: HttpContext): Observable<void> {
    return this.systemUpdateFaxDomainSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemRefreshPbxAccounts()` */
  static readonly SystemRefreshPbxAccountsPath = '/api/system/refresh-pbx-accounts';

  /**
   * Refresh Pbx Accounts for reporting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemRefreshPbxAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemRefreshPbxAccounts$Response(params?: SystemRefreshPbxAccounts$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemRefreshPbxAccounts(this.http, this.rootUrl, params, context);
  }

  /**
   * Refresh Pbx Accounts for reporting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemRefreshPbxAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemRefreshPbxAccounts(params?: SystemRefreshPbxAccounts$Params, context?: HttpContext): Observable<void> {
    return this.systemRefreshPbxAccounts$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemReprovisionAcrobits()` */
  static readonly SystemReprovisionAcrobitsPath = '/api/system/reprovision-acrobits/{customerUserId}';

  /**
   * Notify basic softphone user to reprovision.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemReprovisionAcrobits()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemReprovisionAcrobits$Response(params: SystemReprovisionAcrobits$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemReprovisionAcrobits(this.http, this.rootUrl, params, context);
  }

  /**
   * Notify basic softphone user to reprovision.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemReprovisionAcrobits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemReprovisionAcrobits(params: SystemReprovisionAcrobits$Params, context?: HttpContext): Observable<void> {
    return this.systemReprovisionAcrobits$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemCompose()` */
  static readonly SystemComposePath = '/api/system/compose-notification';

  /**
   * Compose notification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemCompose()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemCompose$Response(params: SystemCompose$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemCompose(this.http, this.rootUrl, params, context);
  }

  /**
   * Compose notification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemCompose$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemCompose(params: SystemCompose$Params, context?: HttpContext): Observable<void> {
    return this.systemCompose$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSendCounterPathLicenseEmail()` */
  static readonly SystemSendCounterPathLicenseEmailPath = '/api/system/counter-path-license-email';

  /**
   * Refresh Pbx Accounts for reporting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSendCounterPathLicenseEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSendCounterPathLicenseEmail$Response(params: SystemSendCounterPathLicenseEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemSendCounterPathLicenseEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * Refresh Pbx Accounts for reporting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSendCounterPathLicenseEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSendCounterPathLicenseEmail(params: SystemSendCounterPathLicenseEmail$Params, context?: HttpContext): Observable<void> {
    return this.systemSendCounterPathLicenseEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSyncBrands()` */
  static readonly SystemSyncBrandsPath = '/api/system/sync-brands';

  /**
   * Sync 10DLC Brands.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSyncBrands()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncBrands$Response(params?: SystemSyncBrands$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemSyncBrands(this.http, this.rootUrl, params, context);
  }

  /**
   * Sync 10DLC Brands.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSyncBrands$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncBrands(params?: SystemSyncBrands$Params, context?: HttpContext): Observable<void> {
    return this.systemSyncBrands$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSyncCampaigns()` */
  static readonly SystemSyncCampaignsPath = '/api/system/sync-campaigns';

  /**
   * Sync 10DLC Campaigns.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSyncCampaigns()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncCampaigns$Response(params?: SystemSyncCampaigns$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemSyncCampaigns(this.http, this.rootUrl, params, context);
  }

  /**
   * Sync 10DLC Campaigns.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSyncCampaigns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncCampaigns(params?: SystemSyncCampaigns$Params, context?: HttpContext): Observable<void> {
    return this.systemSyncCampaigns$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSyncCampaignsStatus()` */
  static readonly SystemSyncCampaignsStatusPath = '/api/system/sync-campaigns-status';

  /**
   * Sync 10DLC Campaigns Status from Bandwidth.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSyncCampaignsStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncCampaignsStatus$Response(params?: SystemSyncCampaignsStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemSyncCampaignsStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Sync 10DLC Campaigns Status from Bandwidth.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSyncCampaignsStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncCampaignsStatus(params?: SystemSyncCampaignsStatus$Params, context?: HttpContext): Observable<void> {
    return this.systemSyncCampaignsStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSyncPhoneNumberCampaigns()` */
  static readonly SystemSyncPhoneNumberCampaignsPath = '/api/system/sync-phone-number-campaigns';

  /**
   * Sync 10DLC Phone Number Campaigns.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSyncPhoneNumberCampaigns()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncPhoneNumberCampaigns$Response(params?: SystemSyncPhoneNumberCampaigns$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemSyncPhoneNumberCampaigns(this.http, this.rootUrl, params, context);
  }

  /**
   * Sync 10DLC Phone Number Campaigns.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSyncPhoneNumberCampaigns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncPhoneNumberCampaigns(params?: SystemSyncPhoneNumberCampaigns$Params, context?: HttpContext): Observable<void> {
    return this.systemSyncPhoneNumberCampaigns$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSyncPendingPhoneNumberCampaigns()` */
  static readonly SystemSyncPendingPhoneNumberCampaignsPath = '/api/system/sync-pending-phone-number-campaigns';

  /**
   * Sync Pending Phone Number Campaigns.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSyncPendingPhoneNumberCampaigns()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncPendingPhoneNumberCampaigns$Response(params?: SystemSyncPendingPhoneNumberCampaigns$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemSyncPendingPhoneNumberCampaigns(this.http, this.rootUrl, params, context);
  }

  /**
   * Sync Pending Phone Number Campaigns.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSyncPendingPhoneNumberCampaigns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncPendingPhoneNumberCampaigns(params?: SystemSyncPendingPhoneNumberCampaigns$Params, context?: HttpContext): Observable<void> {
    return this.systemSyncPendingPhoneNumberCampaigns$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSyncAllCustomersPbx()` */
  static readonly SystemSyncAllCustomersPbxPath = '/api/system/sync-all-customers-pbx';

  /**
   * Sync All Customers Pbx Data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSyncAllCustomersPbx()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncAllCustomersPbx$Response(params?: SystemSyncAllCustomersPbx$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemSyncAllCustomersPbx(this.http, this.rootUrl, params, context);
  }

  /**
   * Sync All Customers Pbx Data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSyncAllCustomersPbx$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncAllCustomersPbx(params?: SystemSyncAllCustomersPbx$Params, context?: HttpContext): Observable<void> {
    return this.systemSyncAllCustomersPbx$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSyncCustomerPbx()` */
  static readonly SystemSyncCustomerPbxPath = '/api/system/sync-customer-pbx';

  /**
   * Sync One Customer All Pbx Data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSyncCustomerPbx()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSyncCustomerPbx$Response(params: SystemSyncCustomerPbx$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemSyncCustomerPbx(this.http, this.rootUrl, params, context);
  }

  /**
   * Sync One Customer All Pbx Data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSyncCustomerPbx$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSyncCustomerPbx(params: SystemSyncCustomerPbx$Params, context?: HttpContext): Observable<void> {
    return this.systemSyncCustomerPbx$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSyncCustomerPbxAccounts()` */
  static readonly SystemSyncCustomerPbxAccountsPath = '/api/system/sync-customer-pbx-accounts';

  /**
   * Sync One Customer Pbx Accounts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSyncCustomerPbxAccounts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSyncCustomerPbxAccounts$Response(params: SystemSyncCustomerPbxAccounts$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemSyncCustomerPbxAccounts(this.http, this.rootUrl, params, context);
  }

  /**
   * Sync One Customer Pbx Accounts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSyncCustomerPbxAccounts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSyncCustomerPbxAccounts(params: SystemSyncCustomerPbxAccounts$Params, context?: HttpContext): Observable<void> {
    return this.systemSyncCustomerPbxAccounts$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSyncCustomerPbxAccount()` */
  static readonly SystemSyncCustomerPbxAccountPath = '/api/system/sync-customer-pbx-account';

  /**
   * Sync One Customer One Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSyncCustomerPbxAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSyncCustomerPbxAccount$Response(params: SystemSyncCustomerPbxAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return systemSyncCustomerPbxAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * Sync One Customer One Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSyncCustomerPbxAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSyncCustomerPbxAccount(params: SystemSyncCustomerPbxAccount$Params, context?: HttpContext): Observable<void> {
    return this.systemSyncCustomerPbxAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
