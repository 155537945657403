/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BillingInfoResponse } from '../models/billing-info-response';
import { ContactTagLookupModel } from '../models/contact-tag-lookup-model';
import { IsRecipientInBlackListResponse } from '../models/is-recipient-in-black-list-response';
import { MediaModel } from '../models/media-model';
import { MessageLogsResponse } from '../models/message-logs-response';
import { MessageModel } from '../models/message-model';
import { MessagingGroupsGridResponse } from '../models/messaging-groups-grid-response';
import { ProMessagesResponse } from '../models/pro-messages-response';
import { proMessagingDeleteFullConversationByBroadcastId } from '../fn/advanced-messaging/pro-messaging-delete-full-conversation-by-broadcast-id';
import { ProMessagingDeleteFullConversationByBroadcastId$Params } from '../fn/advanced-messaging/pro-messaging-delete-full-conversation-by-broadcast-id';
import { proMessagingDeleteFullConversationByGroup } from '../fn/advanced-messaging/pro-messaging-delete-full-conversation-by-group';
import { ProMessagingDeleteFullConversationByGroup$Params } from '../fn/advanced-messaging/pro-messaging-delete-full-conversation-by-group';
import { proMessagingDeleteMessageBody } from '../fn/advanced-messaging/pro-messaging-delete-message-body';
import { ProMessagingDeleteMessageBody$Params } from '../fn/advanced-messaging/pro-messaging-delete-message-body';
import { proMessagingDownloadMedia } from '../fn/advanced-messaging/pro-messaging-download-media';
import { ProMessagingDownloadMedia$Params } from '../fn/advanced-messaging/pro-messaging-download-media';
import { proMessagingGetMessages } from '../fn/advanced-messaging/pro-messaging-get-messages';
import { ProMessagingGetMessages$Params } from '../fn/advanced-messaging/pro-messaging-get-messages';
import { proMessagingGetMessagingGroups } from '../fn/advanced-messaging/pro-messaging-get-messaging-groups';
import { ProMessagingGetMessagingGroups$Params } from '../fn/advanced-messaging/pro-messaging-get-messaging-groups';
import { proMessagingGetMessagingGroupsV2 } from '../fn/advanced-messaging/pro-messaging-get-messaging-groups-v-2';
import { ProMessagingGetMessagingGroupsV2$Params } from '../fn/advanced-messaging/pro-messaging-get-messaging-groups-v-2';
import { proMessagingGetMessagingLimits } from '../fn/advanced-messaging/pro-messaging-get-messaging-limits';
import { ProMessagingGetMessagingLimits$Params } from '../fn/advanced-messaging/pro-messaging-get-messaging-limits';
import { proMessagingGetMyMessagingPhoneNumbers } from '../fn/advanced-messaging/pro-messaging-get-my-messaging-phone-numbers';
import { ProMessagingGetMyMessagingPhoneNumbers$Params } from '../fn/advanced-messaging/pro-messaging-get-my-messaging-phone-numbers';
import { proMessagingIsInBlackList } from '../fn/advanced-messaging/pro-messaging-is-in-black-list';
import { ProMessagingIsInBlackList$Params } from '../fn/advanced-messaging/pro-messaging-is-in-black-list';
import { proMessagingLookupContactsAndTags } from '../fn/advanced-messaging/pro-messaging-lookup-contacts-and-tags';
import { ProMessagingLookupContactsAndTags$Params } from '../fn/advanced-messaging/pro-messaging-lookup-contacts-and-tags';
import { proMessagingPinConversationByGroup } from '../fn/advanced-messaging/pro-messaging-pin-conversation-by-group';
import { ProMessagingPinConversationByGroup$Params } from '../fn/advanced-messaging/pro-messaging-pin-conversation-by-group';
import { proMessagingRemoveFromBlackList } from '../fn/advanced-messaging/pro-messaging-remove-from-black-list';
import { ProMessagingRemoveFromBlackList$Params } from '../fn/advanced-messaging/pro-messaging-remove-from-black-list';
import { proMessagingReportsExportLogs } from '../fn/advanced-messaging/pro-messaging-reports-export-logs';
import { ProMessagingReportsExportLogs$Params } from '../fn/advanced-messaging/pro-messaging-reports-export-logs';
import { proMessagingReportsGetLogs } from '../fn/advanced-messaging/pro-messaging-reports-get-logs';
import { ProMessagingReportsGetLogs$Params } from '../fn/advanced-messaging/pro-messaging-reports-get-logs';
import { proMessagingReportsGetMyMessagingPhoneNumbers } from '../fn/advanced-messaging/pro-messaging-reports-get-my-messaging-phone-numbers';
import { ProMessagingReportsGetMyMessagingPhoneNumbers$Params } from '../fn/advanced-messaging/pro-messaging-reports-get-my-messaging-phone-numbers';
import { proMessagingReportsGetTopNumbersReport } from '../fn/advanced-messaging/pro-messaging-reports-get-top-numbers-report';
import { ProMessagingReportsGetTopNumbersReport$Params } from '../fn/advanced-messaging/pro-messaging-reports-get-top-numbers-report';
import { proMessagingReportsSummaryKeywordsReport } from '../fn/advanced-messaging/pro-messaging-reports-summary-keywords-report';
import { ProMessagingReportsSummaryKeywordsReport$Params } from '../fn/advanced-messaging/pro-messaging-reports-summary-keywords-report';
import { proMessagingReportsSummaryReport } from '../fn/advanced-messaging/pro-messaging-reports-summary-report';
import { ProMessagingReportsSummaryReport$Params } from '../fn/advanced-messaging/pro-messaging-reports-summary-report';
import { proMessagingReportsSummaryReportByDay } from '../fn/advanced-messaging/pro-messaging-reports-summary-report-by-day';
import { ProMessagingReportsSummaryReportByDay$Params } from '../fn/advanced-messaging/pro-messaging-reports-summary-report-by-day';
import { proMessagingSendMessage } from '../fn/advanced-messaging/pro-messaging-send-message';
import { ProMessagingSendMessage$Params } from '../fn/advanced-messaging/pro-messaging-send-message';
import { proMessagingStreamMedia } from '../fn/advanced-messaging/pro-messaging-stream-media';
import { ProMessagingStreamMedia$Params } from '../fn/advanced-messaging/pro-messaging-stream-media';
import { proMessagingUploadMedia } from '../fn/advanced-messaging/pro-messaging-upload-media';
import { ProMessagingUploadMedia$Params } from '../fn/advanced-messaging/pro-messaging-upload-media';
import { RemoveRecipientFromBlackListResponse } from '../models/remove-recipient-from-black-list-response';
import { SmsPhoneNumberModel } from '../models/sms-phone-number-model';
import { SummaryByDayReportModel } from '../models/summary-by-day-report-model';
import { SummaryKeywordsReportModel } from '../models/summary-keywords-report-model';
import { SummaryReportModel } from '../models/summary-report-model';
import { TopNumbersReportModel } from '../models/top-numbers-report-model';

@Injectable({ providedIn: 'root' })
export class AdvancedMessagingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `proMessagingSendMessage()` */
  static readonly ProMessagingSendMessagePath = '/api/advanced-messaging/send-message';

  /**
   * Send Message.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingSendMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingSendMessage$Response(params: ProMessagingSendMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<MessageModel>> {
    return proMessagingSendMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Send Message.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingSendMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingSendMessage(params: ProMessagingSendMessage$Params, context?: HttpContext): Observable<MessageModel> {
    return this.proMessagingSendMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageModel>): MessageModel => r.body)
    );
  }

  /** Path part for operation `proMessagingUploadMedia()` */
  static readonly ProMessagingUploadMediaPath = '/api/advanced-messaging/upload-media';

  /**
   * Upload Media.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingUploadMedia()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  proMessagingUploadMedia$Response(params: ProMessagingUploadMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaModel>> {
    return proMessagingUploadMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload Media.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingUploadMedia$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  proMessagingUploadMedia(params: ProMessagingUploadMedia$Params, context?: HttpContext): Observable<MediaModel> {
    return this.proMessagingUploadMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaModel>): MediaModel => r.body)
    );
  }

  /** Path part for operation `proMessagingGetMessagingLimits()` */
  static readonly ProMessagingGetMessagingLimitsPath = '/api/advanced-messaging/messaging-limits';

  /**
   * Get messaging limits and current usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingGetMessagingLimits()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingGetMessagingLimits$Response(params?: ProMessagingGetMessagingLimits$Params, context?: HttpContext): Observable<StrictHttpResponse<BillingInfoResponse>> {
    return proMessagingGetMessagingLimits(this.http, this.rootUrl, params, context);
  }

  /**
   * Get messaging limits and current usage.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingGetMessagingLimits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingGetMessagingLimits(params?: ProMessagingGetMessagingLimits$Params, context?: HttpContext): Observable<BillingInfoResponse> {
    return this.proMessagingGetMessagingLimits$Response(params, context).pipe(
      map((r: StrictHttpResponse<BillingInfoResponse>): BillingInfoResponse => r.body)
    );
  }

  /** Path part for operation `proMessagingGetMyMessagingPhoneNumbers()` */
  static readonly ProMessagingGetMyMessagingPhoneNumbersPath = '/api/advanced-messaging/my-messaging-phone-numbers';

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingGetMyMessagingPhoneNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingGetMyMessagingPhoneNumbers$Response(params?: ProMessagingGetMyMessagingPhoneNumbers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SmsPhoneNumberModel>>> {
    return proMessagingGetMyMessagingPhoneNumbers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingGetMyMessagingPhoneNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingGetMyMessagingPhoneNumbers(params?: ProMessagingGetMyMessagingPhoneNumbers$Params, context?: HttpContext): Observable<Array<SmsPhoneNumberModel>> {
    return this.proMessagingGetMyMessagingPhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SmsPhoneNumberModel>>): Array<SmsPhoneNumberModel> => r.body)
    );
  }

  /** Path part for operation `proMessagingGetMessagingGroups()` */
  static readonly ProMessagingGetMessagingGroupsPath = '/api/advanced-messaging/messaging-groups/{phoneNumber}';

  /**
   * Get messaging phone number groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingGetMessagingGroups()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingGetMessagingGroups$Response(params: ProMessagingGetMessagingGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<MessagingGroupsGridResponse>> {
    return proMessagingGetMessagingGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * Get messaging phone number groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingGetMessagingGroups$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingGetMessagingGroups(params: ProMessagingGetMessagingGroups$Params, context?: HttpContext): Observable<MessagingGroupsGridResponse> {
    return this.proMessagingGetMessagingGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessagingGroupsGridResponse>): MessagingGroupsGridResponse => r.body)
    );
  }

  /** Path part for operation `proMessagingGetMessagingGroupsV2()` */
  static readonly ProMessagingGetMessagingGroupsV2Path = '/api/advanced-messaging/messaging-groups-v2/{phoneNumber}';

  /**
   * Get messaging phone number groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingGetMessagingGroupsV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingGetMessagingGroupsV2$Response(params: ProMessagingGetMessagingGroupsV2$Params, context?: HttpContext): Observable<StrictHttpResponse<MessagingGroupsGridResponse>> {
    return proMessagingGetMessagingGroupsV2(this.http, this.rootUrl, params, context);
  }

  /**
   * Get messaging phone number groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingGetMessagingGroupsV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingGetMessagingGroupsV2(params: ProMessagingGetMessagingGroupsV2$Params, context?: HttpContext): Observable<MessagingGroupsGridResponse> {
    return this.proMessagingGetMessagingGroupsV2$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessagingGroupsGridResponse>): MessagingGroupsGridResponse => r.body)
    );
  }

  /** Path part for operation `proMessagingGetMessages()` */
  static readonly ProMessagingGetMessagesPath = '/api/advanced-messaging/get-messages';

  /**
   * Get messages from/to specific sender.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingGetMessages()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingGetMessages$Response(params: ProMessagingGetMessages$Params, context?: HttpContext): Observable<StrictHttpResponse<ProMessagesResponse>> {
    return proMessagingGetMessages(this.http, this.rootUrl, params, context);
  }

  /**
   * Get messages from/to specific sender.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingGetMessages$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingGetMessages(params: ProMessagingGetMessages$Params, context?: HttpContext): Observable<ProMessagesResponse> {
    return this.proMessagingGetMessages$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProMessagesResponse>): ProMessagesResponse => r.body)
    );
  }

  /** Path part for operation `proMessagingDeleteMessageBody()` */
  static readonly ProMessagingDeleteMessageBodyPath = '/api/advanced-messaging/message/{messageId}';

  /**
   * Delete Message body.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingDeleteMessageBody()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingDeleteMessageBody$Response(params: ProMessagingDeleteMessageBody$Params, context?: HttpContext): Observable<StrictHttpResponse<MessageModel>> {
    return proMessagingDeleteMessageBody(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Message body.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingDeleteMessageBody$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingDeleteMessageBody(params: ProMessagingDeleteMessageBody$Params, context?: HttpContext): Observable<MessageModel> {
    return this.proMessagingDeleteMessageBody$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageModel>): MessageModel => r.body)
    );
  }

  /** Path part for operation `proMessagingPinConversationByGroup()` */
  static readonly ProMessagingPinConversationByGroupPath = '/api/advanced-messaging/pin-conversation/{groupKey}';

  /**
   * Pin Conversation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingPinConversationByGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingPinConversationByGroup$Response(params: ProMessagingPinConversationByGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return proMessagingPinConversationByGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Pin Conversation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingPinConversationByGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingPinConversationByGroup(params: ProMessagingPinConversationByGroup$Params, context?: HttpContext): Observable<boolean> {
    return this.proMessagingPinConversationByGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `proMessagingDeleteFullConversationByGroup()` */
  static readonly ProMessagingDeleteFullConversationByGroupPath = '/api/advanced-messaging/conversation-by-group/{groupKey}';

  /**
   * Delete Full Conversation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingDeleteFullConversationByGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingDeleteFullConversationByGroup$Response(params: ProMessagingDeleteFullConversationByGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return proMessagingDeleteFullConversationByGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Full Conversation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingDeleteFullConversationByGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingDeleteFullConversationByGroup(params: ProMessagingDeleteFullConversationByGroup$Params, context?: HttpContext): Observable<boolean> {
    return this.proMessagingDeleteFullConversationByGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `proMessagingDeleteFullConversationByBroadcastId()` */
  static readonly ProMessagingDeleteFullConversationByBroadcastIdPath = '/api/advanced-messaging/conversation-by-broadcast/{broadcastId}';

  /**
   * Delete Full Conversation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingDeleteFullConversationByBroadcastId()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingDeleteFullConversationByBroadcastId$Response(params: ProMessagingDeleteFullConversationByBroadcastId$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return proMessagingDeleteFullConversationByBroadcastId(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Full Conversation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingDeleteFullConversationByBroadcastId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingDeleteFullConversationByBroadcastId(params: ProMessagingDeleteFullConversationByBroadcastId$Params, context?: HttpContext): Observable<boolean> {
    return this.proMessagingDeleteFullConversationByBroadcastId$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `proMessagingIsInBlackList()` */
  static readonly ProMessagingIsInBlackListPath = '/api/advanced-messaging/is-in-black-list';

  /**
   * Check if recipient is in black list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingIsInBlackList()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingIsInBlackList$Response(params: ProMessagingIsInBlackList$Params, context?: HttpContext): Observable<StrictHttpResponse<IsRecipientInBlackListResponse>> {
    return proMessagingIsInBlackList(this.http, this.rootUrl, params, context);
  }

  /**
   * Check if recipient is in black list.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingIsInBlackList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingIsInBlackList(params: ProMessagingIsInBlackList$Params, context?: HttpContext): Observable<IsRecipientInBlackListResponse> {
    return this.proMessagingIsInBlackList$Response(params, context).pipe(
      map((r: StrictHttpResponse<IsRecipientInBlackListResponse>): IsRecipientInBlackListResponse => r.body)
    );
  }

  /** Path part for operation `proMessagingRemoveFromBlackList()` */
  static readonly ProMessagingRemoveFromBlackListPath = '/api/advanced-messaging/remove-from-black-list';

  /**
   * Remove recipient from black list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingRemoveFromBlackList()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingRemoveFromBlackList$Response(params: ProMessagingRemoveFromBlackList$Params, context?: HttpContext): Observable<StrictHttpResponse<RemoveRecipientFromBlackListResponse>> {
    return proMessagingRemoveFromBlackList(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove recipient from black list.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingRemoveFromBlackList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingRemoveFromBlackList(params: ProMessagingRemoveFromBlackList$Params, context?: HttpContext): Observable<RemoveRecipientFromBlackListResponse> {
    return this.proMessagingRemoveFromBlackList$Response(params, context).pipe(
      map((r: StrictHttpResponse<RemoveRecipientFromBlackListResponse>): RemoveRecipientFromBlackListResponse => r.body)
    );
  }

  /** Path part for operation `proMessagingLookupContactsAndTags()` */
  static readonly ProMessagingLookupContactsAndTagsPath = '/api/advanced-messaging/lookup-contacts';

  /**
   * Lookup contacts and tags.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingLookupContactsAndTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingLookupContactsAndTags$Response(params: ProMessagingLookupContactsAndTags$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContactTagLookupModel>>> {
    return proMessagingLookupContactsAndTags(this.http, this.rootUrl, params, context);
  }

  /**
   * Lookup contacts and tags.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingLookupContactsAndTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingLookupContactsAndTags(params: ProMessagingLookupContactsAndTags$Params, context?: HttpContext): Observable<Array<ContactTagLookupModel>> {
    return this.proMessagingLookupContactsAndTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactTagLookupModel>>): Array<ContactTagLookupModel> => r.body)
    );
  }

  /** Path part for operation `proMessagingStreamMedia()` */
  static readonly ProMessagingStreamMediaPath = '/api/advanced-messaging/stream-media/*';

  /**
   * Stream Media.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingStreamMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingStreamMedia$Response(params?: ProMessagingStreamMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return proMessagingStreamMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * Stream Media.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingStreamMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingStreamMedia(params?: ProMessagingStreamMedia$Params, context?: HttpContext): Observable<void> {
    return this.proMessagingStreamMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `proMessagingDownloadMedia()` */
  static readonly ProMessagingDownloadMediaPath = '/api/advanced-messaging/download-media/*';

  /**
   * Download Media.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingDownloadMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingDownloadMedia$Response(params?: ProMessagingDownloadMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return proMessagingDownloadMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * Download Media.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingDownloadMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingDownloadMedia(params?: ProMessagingDownloadMedia$Params, context?: HttpContext): Observable<void> {
    return this.proMessagingDownloadMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `proMessagingReportsSummaryReport()` */
  static readonly ProMessagingReportsSummaryReportPath = '/api/advanced-messaging/reports/summary';

  /**
   * Summary Report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingReportsSummaryReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsSummaryReport$Response(params: ProMessagingReportsSummaryReport$Params, context?: HttpContext): Observable<StrictHttpResponse<SummaryReportModel>> {
    return proMessagingReportsSummaryReport(this.http, this.rootUrl, params, context);
  }

  /**
   * Summary Report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingReportsSummaryReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsSummaryReport(params: ProMessagingReportsSummaryReport$Params, context?: HttpContext): Observable<SummaryReportModel> {
    return this.proMessagingReportsSummaryReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<SummaryReportModel>): SummaryReportModel => r.body)
    );
  }

  /** Path part for operation `proMessagingReportsSummaryKeywordsReport()` */
  static readonly ProMessagingReportsSummaryKeywordsReportPath = '/api/advanced-messaging/reports/keywords-summary';

  /**
   * Keywords Summary Report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingReportsSummaryKeywordsReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsSummaryKeywordsReport$Response(params: ProMessagingReportsSummaryKeywordsReport$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SummaryKeywordsReportModel>>> {
    return proMessagingReportsSummaryKeywordsReport(this.http, this.rootUrl, params, context);
  }

  /**
   * Keywords Summary Report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingReportsSummaryKeywordsReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsSummaryKeywordsReport(params: ProMessagingReportsSummaryKeywordsReport$Params, context?: HttpContext): Observable<Array<SummaryKeywordsReportModel>> {
    return this.proMessagingReportsSummaryKeywordsReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SummaryKeywordsReportModel>>): Array<SummaryKeywordsReportModel> => r.body)
    );
  }

  /** Path part for operation `proMessagingReportsSummaryReportByDay()` */
  static readonly ProMessagingReportsSummaryReportByDayPath = '/api/advanced-messaging/reports/summary-by-day';

  /**
   * Summary Report By Day.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingReportsSummaryReportByDay()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsSummaryReportByDay$Response(params: ProMessagingReportsSummaryReportByDay$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SummaryByDayReportModel>>> {
    return proMessagingReportsSummaryReportByDay(this.http, this.rootUrl, params, context);
  }

  /**
   * Summary Report By Day.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingReportsSummaryReportByDay$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsSummaryReportByDay(params: ProMessagingReportsSummaryReportByDay$Params, context?: HttpContext): Observable<Array<SummaryByDayReportModel>> {
    return this.proMessagingReportsSummaryReportByDay$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SummaryByDayReportModel>>): Array<SummaryByDayReportModel> => r.body)
    );
  }

  /** Path part for operation `proMessagingReportsGetTopNumbersReport()` */
  static readonly ProMessagingReportsGetTopNumbersReportPath = '/api/advanced-messaging/reports/top-numbers';

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingReportsGetTopNumbersReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsGetTopNumbersReport$Response(params: ProMessagingReportsGetTopNumbersReport$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TopNumbersReportModel>>> {
    return proMessagingReportsGetTopNumbersReport(this.http, this.rootUrl, params, context);
  }

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingReportsGetTopNumbersReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsGetTopNumbersReport(params: ProMessagingReportsGetTopNumbersReport$Params, context?: HttpContext): Observable<Array<TopNumbersReportModel>> {
    return this.proMessagingReportsGetTopNumbersReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TopNumbersReportModel>>): Array<TopNumbersReportModel> => r.body)
    );
  }

  /** Path part for operation `proMessagingReportsGetLogs()` */
  static readonly ProMessagingReportsGetLogsPath = '/api/advanced-messaging/reports/logs';

  /**
   * Get messages logs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingReportsGetLogs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsGetLogs$Response(params: ProMessagingReportsGetLogs$Params, context?: HttpContext): Observable<StrictHttpResponse<MessageLogsResponse>> {
    return proMessagingReportsGetLogs(this.http, this.rootUrl, params, context);
  }

  /**
   * Get messages logs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingReportsGetLogs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsGetLogs(params: ProMessagingReportsGetLogs$Params, context?: HttpContext): Observable<MessageLogsResponse> {
    return this.proMessagingReportsGetLogs$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageLogsResponse>): MessageLogsResponse => r.body)
    );
  }

  /** Path part for operation `proMessagingReportsExportLogs()` */
  static readonly ProMessagingReportsExportLogsPath = '/api/advanced-messaging/reports/logs/export';

  /**
   * Initiate messages logs export.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingReportsExportLogs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsExportLogs$Response(params: ProMessagingReportsExportLogs$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return proMessagingReportsExportLogs(this.http, this.rootUrl, params, context);
  }

  /**
   * Initiate messages logs export.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingReportsExportLogs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsExportLogs(params: ProMessagingReportsExportLogs$Params, context?: HttpContext): Observable<string> {
    return this.proMessagingReportsExportLogs$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `proMessagingReportsGetMyMessagingPhoneNumbers()` */
  static readonly ProMessagingReportsGetMyMessagingPhoneNumbersPath = '/api/advanced-messaging/reports/my-messaging-phone-numbers';

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingReportsGetMyMessagingPhoneNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingReportsGetMyMessagingPhoneNumbers$Response(params?: ProMessagingReportsGetMyMessagingPhoneNumbers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SmsPhoneNumberModel>>> {
    return proMessagingReportsGetMyMessagingPhoneNumbers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingReportsGetMyMessagingPhoneNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingReportsGetMyMessagingPhoneNumbers(params?: ProMessagingReportsGetMyMessagingPhoneNumbers$Params, context?: HttpContext): Observable<Array<SmsPhoneNumberModel>> {
    return this.proMessagingReportsGetMyMessagingPhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SmsPhoneNumberModel>>): Array<SmsPhoneNumberModel> => r.body)
    );
  }

}
