/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { hhaSearchPatientsAndCaregivers } from '../fn/hha-exchange/hha-search-patients-and-caregivers';
import { HhaSearchPatientsAndCaregivers$Params } from '../fn/hha-exchange/hha-search-patients-and-caregivers';
import { SearchPatientCaregiverByPhoneResponse } from '../models/search-patient-caregiver-by-phone-response';

@Injectable({ providedIn: 'root' })
export class HhaExchangeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `hhaSearchPatientsAndCaregivers()` */
  static readonly HhaSearchPatientsAndCaregiversPath = '/api/hha/search-by-number/{number}';

  /**
   * Search Patient/Caregiver By phone number.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hhaSearchPatientsAndCaregivers()` instead.
   *
   * This method doesn't expect any request body.
   */
  hhaSearchPatientsAndCaregivers$Response(params: HhaSearchPatientsAndCaregivers$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchPatientCaregiverByPhoneResponse>> {
    return hhaSearchPatientsAndCaregivers(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Patient/Caregiver By phone number.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hhaSearchPatientsAndCaregivers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hhaSearchPatientsAndCaregivers(params: HhaSearchPatientsAndCaregivers$Params, context?: HttpContext): Observable<SearchPatientCaregiverByPhoneResponse> {
    return this.hhaSearchPatientsAndCaregivers$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchPatientCaregiverByPhoneResponse>): SearchPatientCaregiverByPhoneResponse => r.body)
    );
  }

}
