/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { firewallDeleteIpAddressById } from '../fn/firewall/firewall-delete-ip-address-by-id';
import { FirewallDeleteIpAddressById$Params } from '../fn/firewall/firewall-delete-ip-address-by-id';
import { firewallGetClientIpAddress } from '../fn/firewall/firewall-get-client-ip-address';
import { FirewallGetClientIpAddress$Params } from '../fn/firewall/firewall-get-client-ip-address';
import { firewallGetFirewallLimits } from '../fn/firewall/firewall-get-firewall-limits';
import { FirewallGetFirewallLimits$Params } from '../fn/firewall/firewall-get-firewall-limits';
import { firewallGetIpAddressById } from '../fn/firewall/firewall-get-ip-address-by-id';
import { FirewallGetIpAddressById$Params } from '../fn/firewall/firewall-get-ip-address-by-id';
import { firewallGetWhiteList } from '../fn/firewall/firewall-get-white-list';
import { FirewallGetWhiteList$Params } from '../fn/firewall/firewall-get-white-list';
import { FirewallGridResponse } from '../models/firewall-grid-response';
import { FirewallModel } from '../models/firewall-model';
import { firewallRequestExtendLimits } from '../fn/firewall/firewall-request-extend-limits';
import { FirewallRequestExtendLimits$Params } from '../fn/firewall/firewall-request-extend-limits';
import { firewallSaveIpAddress } from '../fn/firewall/firewall-save-ip-address';
import { FirewallSaveIpAddress$Params } from '../fn/firewall/firewall-save-ip-address';

@Injectable({ providedIn: 'root' })
export class FirewallService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `firewallGetWhiteList()` */
  static readonly FirewallGetWhiteListPath = '/api/firewall/list';

  /**
   * Get all white-list IP.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `firewallGetWhiteList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  firewallGetWhiteList$Response(params: FirewallGetWhiteList$Params, context?: HttpContext): Observable<StrictHttpResponse<FirewallGridResponse>> {
    return firewallGetWhiteList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all white-list IP.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `firewallGetWhiteList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  firewallGetWhiteList(params: FirewallGetWhiteList$Params, context?: HttpContext): Observable<FirewallGridResponse> {
    return this.firewallGetWhiteList$Response(params, context).pipe(
      map((r: StrictHttpResponse<FirewallGridResponse>): FirewallGridResponse => r.body)
    );
  }

  /** Path part for operation `firewallGetIpAddressById()` */
  static readonly FirewallGetIpAddressByIdPath = '/api/firewall/ip-address/{id}';

  /**
   * Get account ip address details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `firewallGetIpAddressById()` instead.
   *
   * This method doesn't expect any request body.
   */
  firewallGetIpAddressById$Response(params: FirewallGetIpAddressById$Params, context?: HttpContext): Observable<StrictHttpResponse<FirewallModel>> {
    return firewallGetIpAddressById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get account ip address details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `firewallGetIpAddressById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  firewallGetIpAddressById(params: FirewallGetIpAddressById$Params, context?: HttpContext): Observable<FirewallModel> {
    return this.firewallGetIpAddressById$Response(params, context).pipe(
      map((r: StrictHttpResponse<FirewallModel>): FirewallModel => r.body)
    );
  }

  /** Path part for operation `firewallDeleteIpAddressById()` */
  static readonly FirewallDeleteIpAddressByIdPath = '/api/firewall/ip-address/{id}';

  /**
   * Delete account ip address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `firewallDeleteIpAddressById()` instead.
   *
   * This method doesn't expect any request body.
   */
  firewallDeleteIpAddressById$Response(params: FirewallDeleteIpAddressById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return firewallDeleteIpAddressById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete account ip address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `firewallDeleteIpAddressById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  firewallDeleteIpAddressById(params: FirewallDeleteIpAddressById$Params, context?: HttpContext): Observable<void> {
    return this.firewallDeleteIpAddressById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `firewallGetFirewallLimits()` */
  static readonly FirewallGetFirewallLimitsPath = '/api/firewall/firewall-limits';

  /**
   * Get account firewall limits.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `firewallGetFirewallLimits()` instead.
   *
   * This method doesn't expect any request body.
   */
  firewallGetFirewallLimits$Response(params?: FirewallGetFirewallLimits$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return firewallGetFirewallLimits(this.http, this.rootUrl, params, context);
  }

  /**
   * Get account firewall limits.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `firewallGetFirewallLimits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  firewallGetFirewallLimits(params?: FirewallGetFirewallLimits$Params, context?: HttpContext): Observable<number> {
    return this.firewallGetFirewallLimits$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `firewallSaveIpAddress()` */
  static readonly FirewallSaveIpAddressPath = '/api/firewall/save-ip-address';

  /**
   * Save account ip address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `firewallSaveIpAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  firewallSaveIpAddress$Response(params: FirewallSaveIpAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return firewallSaveIpAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Save account ip address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `firewallSaveIpAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  firewallSaveIpAddress(params: FirewallSaveIpAddress$Params, context?: HttpContext): Observable<void> {
    return this.firewallSaveIpAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `firewallGetClientIpAddress()` */
  static readonly FirewallGetClientIpAddressPath = '/api/firewall/my-ip-address';

  /**
   * Get client IP address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `firewallGetClientIpAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  firewallGetClientIpAddress$Response(params?: FirewallGetClientIpAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return firewallGetClientIpAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Get client IP address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `firewallGetClientIpAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  firewallGetClientIpAddress(params?: FirewallGetClientIpAddress$Params, context?: HttpContext): Observable<string> {
    return this.firewallGetClientIpAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `firewallRequestExtendLimits()` */
  static readonly FirewallRequestExtendLimitsPath = '/api/firewall/request-extend-limits';

  /**
   * Request support to extend firewall limits.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `firewallRequestExtendLimits()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  firewallRequestExtendLimits$Response(params: FirewallRequestExtendLimits$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return firewallRequestExtendLimits(this.http, this.rootUrl, params, context);
  }

  /**
   * Request support to extend firewall limits.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `firewallRequestExtendLimits$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  firewallRequestExtendLimits(params: FirewallRequestExtendLimits$Params, context?: HttpContext): Observable<void> {
    return this.firewallRequestExtendLimits$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
