import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LogoutComponent} from './logout/logout.component';
import {RouterModule} from '@angular/router';
import {EditorsModule} from '../editors/editors.module';
import {CoreModule} from '../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ContactsDialogComponent} from './contacts/contacts-dialog.component';
import {MatConfirmDialogComponent} from './mat-confirm/mat-confirm-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatConfirmHtmlDialogComponent} from './mat-confirm-html/mat-confirm-html-dialog.component';
import {MatCallLogsFilterDialogComponent} from './mat-call-logs-filter/mat-call-logs-filter-dialog.component';
import {MatAlertDialogComponent} from './mat-alert/mat-alert-dialog.component';
import {MatExportDialogComponent} from './mat-export/mat-export-dialog.component';
import {MatAlertHtmlDialogComponent} from './mat-alert-html/mat-alert-dialog.component';
import {FlexModule} from '@angular/flex-layout';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ContactsDialogGridComponent} from './contacts/contacts-dialog-grid.component';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDateRangeDialogComponent} from './mat-date-range/mat-date-range-dialog/mat-date-range-dialog.component';
import {MatDateRangeComponent} from './mat-date-range/mat-date-range.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {GridFilterComponent} from './grid-filter/grid-filter.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatSwitchCompanyDialogComponent} from './mat-switch-company-dialog/mat-switch-company-dialog.component';
import {TwoFactorSetupDialogComponent} from './two-factor-setup-dialog/two-factor-setup-dialog.component';
import {MatStepperModule} from '@angular/material/stepper';
import { VersionCheckerDialogComponent } from './version-checker-dialog/version-checker-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    CoreModule,
    EditorsModule,
    MatDialogModule,
    MatButtonModule,
    FlexModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    MatChipsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatStepperModule
  ],
  declarations: [
    LogoutComponent,
    ContactsDialogComponent,
    ContactsDialogGridComponent,
    MatConfirmDialogComponent,
    MatConfirmHtmlDialogComponent,
    MatCallLogsFilterDialogComponent,
    MatAlertDialogComponent,
    MatExportDialogComponent,
    MatAlertHtmlDialogComponent,
    MatDateRangeComponent,
    MatDateRangeDialogComponent,
    GridFilterComponent,
    MatSwitchCompanyDialogComponent,
    TwoFactorSetupDialogComponent,
    VersionCheckerDialogComponent,
  ],
  exports: [
    LogoutComponent,
    ContactsDialogComponent,
    ContactsDialogGridComponent,
    MatConfirmDialogComponent,
    MatConfirmHtmlDialogComponent,
    MatCallLogsFilterDialogComponent,
    MatAlertDialogComponent,
    MatExportDialogComponent,
    MatAlertHtmlDialogComponent,
    MatDateRangeComponent,
    MatDateRangeDialogComponent,
    GridFilterComponent,
    MatSwitchCompanyDialogComponent,
    TwoFactorSetupDialogComponent
  ]
})
export class DialogsModule {
}
