/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DownloadLinkModel } from '../models/download-link-model';
import { softphoneChangePassword } from '../fn/softphone/softphone-change-password';
import { SoftphoneChangePassword$Params } from '../fn/softphone/softphone-change-password';
import { softphoneDownloadLinks } from '../fn/softphone/softphone-download-links';
import { SoftphoneDownloadLinks$Params } from '../fn/softphone/softphone-download-links';
import { softphoneSummary } from '../fn/softphone/softphone-summary';
import { SoftphoneSummary$Params } from '../fn/softphone/softphone-summary';
import { SoftPhoneSummaryModel } from '../models/soft-phone-summary-model';
import { softphoneTest } from '../fn/softphone/softphone-test';
import { SoftphoneTest$Params } from '../fn/softphone/softphone-test';

@Injectable({ providedIn: 'root' })
export class SoftphoneService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `softphoneSummary()` */
  static readonly SoftphoneSummaryPath = '/api/softphone/summary';

  /**
   * Get user softphone summary details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `softphoneSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  softphoneSummary$Response(params?: SoftphoneSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<SoftPhoneSummaryModel>> {
    return softphoneSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * Get user softphone summary details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `softphoneSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  softphoneSummary(params?: SoftphoneSummary$Params, context?: HttpContext): Observable<SoftPhoneSummaryModel> {
    return this.softphoneSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<SoftPhoneSummaryModel>): SoftPhoneSummaryModel => r.body)
    );
  }

  /** Path part for operation `softphoneTest()` */
  static readonly SoftphoneTestPath = '/api/softphone/test';

  /**
   * Test CounterPath integration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `softphoneTest()` instead.
   *
   * This method doesn't expect any request body.
   */
  softphoneTest$Response(params?: SoftphoneTest$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return softphoneTest(this.http, this.rootUrl, params, context);
  }

  /**
   * Test CounterPath integration.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `softphoneTest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  softphoneTest(params?: SoftphoneTest$Params, context?: HttpContext): Observable<string> {
    return this.softphoneTest$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `softphoneDownloadLinks()` */
  static readonly SoftphoneDownloadLinksPath = '/api/softphone/links';

  /**
   * Get list of softphone app download links.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `softphoneDownloadLinks()` instead.
   *
   * This method doesn't expect any request body.
   */
  softphoneDownloadLinks$Response(params?: SoftphoneDownloadLinks$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DownloadLinkModel>>> {
    return softphoneDownloadLinks(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of softphone app download links.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `softphoneDownloadLinks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  softphoneDownloadLinks(params?: SoftphoneDownloadLinks$Params, context?: HttpContext): Observable<Array<DownloadLinkModel>> {
    return this.softphoneDownloadLinks$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DownloadLinkModel>>): Array<DownloadLinkModel> => r.body)
    );
  }

  /** Path part for operation `softphoneChangePassword()` */
  static readonly SoftphoneChangePasswordPath = '/api/softphone/change-password';

  /**
   * Change SoftPhone password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `softphoneChangePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  softphoneChangePassword$Response(params: SoftphoneChangePassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return softphoneChangePassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Change SoftPhone password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `softphoneChangePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  softphoneChangePassword(params: SoftphoneChangePassword$Params, context?: HttpContext): Observable<void> {
    return this.softphoneChangePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
