/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { LookupGridResponse } from '../../models/lookup-grid-response';

export interface LookupGetCustomerUserEmails$Params {
  onlySms?: boolean;
  onlyFax?: boolean;
}

export function lookupGetCustomerUserEmails(http: HttpClient, rootUrl: string, params?: LookupGetCustomerUserEmails$Params, context?: HttpContext): Observable<StrictHttpResponse<LookupGridResponse>> {
  const rb = new RequestBuilder(rootUrl, lookupGetCustomerUserEmails.PATH, 'get');
  if (params) {
    rb.query('onlySms', params.onlySms, {});
    rb.query('onlyFax', params.onlyFax, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<LookupGridResponse>;
    })
  );
}

lookupGetCustomerUserEmails.PATH = '/api/lookup/customer-user-emails';
