import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, interval, Subscription, take, tap } from 'rxjs';

@Component({
  selector: 'app-version-checker-dialog',
  templateUrl: './version-checker-dialog.component.html',
  styleUrls: ['./version-checker-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionCheckerDialogComponent implements OnInit, OnDestroy {
  public readonly countdown$ = new BehaviorSubject<number>(60);

  private _countdownSubscription!: Subscription;

  public ngOnInit(): void {
    this.startCountdown();
  }

  public ngOnDestroy(): void {
    this._countdownSubscription.unsubscribe();
  }

  public onReload(): void {
    window.location.reload();
  }

  private startCountdown(): void {
    this._countdownSubscription = interval(1000)
      .pipe(
        take(this.countdown$.getValue()),
        tap(() => {
          const currentValue = this.countdown$.getValue() - 1;
          this.countdown$.next(currentValue);
        }),
      )
      .subscribe({
        complete: () => this.onReload(),
      });
  }
}
