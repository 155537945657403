/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BooleanResponse } from '../models/boolean-response';
import { pbxSyncSyncAllCustomersPbx } from '../fn/pbx-sync/pbx-sync-sync-all-customers-pbx';
import { PbxSyncSyncAllCustomersPbx$Params } from '../fn/pbx-sync/pbx-sync-sync-all-customers-pbx';
import { pbxSyncSyncCustomerPbx } from '../fn/pbx-sync/pbx-sync-sync-customer-pbx';
import { PbxSyncSyncCustomerPbx$Params } from '../fn/pbx-sync/pbx-sync-sync-customer-pbx';
import { pbxSyncSyncCustomerPbxAccount } from '../fn/pbx-sync/pbx-sync-sync-customer-pbx-account';
import { PbxSyncSyncCustomerPbxAccount$Params } from '../fn/pbx-sync/pbx-sync-sync-customer-pbx-account';
import { pbxSyncSyncCustomerPbxAccounts } from '../fn/pbx-sync/pbx-sync-sync-customer-pbx-accounts';
import { PbxSyncSyncCustomerPbxAccounts$Params } from '../fn/pbx-sync/pbx-sync-sync-customer-pbx-accounts';
import { SyncCustomerPbxResponse } from '../models/sync-customer-pbx-response';
import { SyncPbxResultModel } from '../models/sync-pbx-result-model';

@Injectable({ providedIn: 'root' })
export class PbxSyncService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `pbxSyncSyncAllCustomersPbx()` */
  static readonly PbxSyncSyncAllCustomersPbxPath = '/api/pbx-sync/sync-all-customers-pbx';

  /**
   * Sync All Customers Pbx data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxSyncSyncAllCustomersPbx()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxSyncSyncAllCustomersPbx$Response(params?: PbxSyncSyncAllCustomersPbx$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanResponse>> {
    return pbxSyncSyncAllCustomersPbx(this.http, this.rootUrl, params, context);
  }

  /**
   * Sync All Customers Pbx data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxSyncSyncAllCustomersPbx$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxSyncSyncAllCustomersPbx(params?: PbxSyncSyncAllCustomersPbx$Params, context?: HttpContext): Observable<BooleanResponse> {
    return this.pbxSyncSyncAllCustomersPbx$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanResponse>): BooleanResponse => r.body)
    );
  }

  /** Path part for operation `pbxSyncSyncCustomerPbx()` */
  static readonly PbxSyncSyncCustomerPbxPath = '/api/pbx-sync/sync-customer-pbx';

  /**
   * Sync Customer Pbx data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxSyncSyncCustomerPbx()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxSyncSyncCustomerPbx$Response(params: PbxSyncSyncCustomerPbx$Params, context?: HttpContext): Observable<StrictHttpResponse<SyncCustomerPbxResponse>> {
    return pbxSyncSyncCustomerPbx(this.http, this.rootUrl, params, context);
  }

  /**
   * Sync Customer Pbx data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxSyncSyncCustomerPbx$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxSyncSyncCustomerPbx(params: PbxSyncSyncCustomerPbx$Params, context?: HttpContext): Observable<SyncCustomerPbxResponse> {
    return this.pbxSyncSyncCustomerPbx$Response(params, context).pipe(
      map((r: StrictHttpResponse<SyncCustomerPbxResponse>): SyncCustomerPbxResponse => r.body)
    );
  }

  /** Path part for operation `pbxSyncSyncCustomerPbxAccounts()` */
  static readonly PbxSyncSyncCustomerPbxAccountsPath = '/api/pbx-sync/sync-customer-pbx-accounts';

  /**
   * Sync Customer Pbx data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxSyncSyncCustomerPbxAccounts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxSyncSyncCustomerPbxAccounts$Response(params: PbxSyncSyncCustomerPbxAccounts$Params, context?: HttpContext): Observable<StrictHttpResponse<SyncPbxResultModel>> {
    return pbxSyncSyncCustomerPbxAccounts(this.http, this.rootUrl, params, context);
  }

  /**
   * Sync Customer Pbx data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxSyncSyncCustomerPbxAccounts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxSyncSyncCustomerPbxAccounts(params: PbxSyncSyncCustomerPbxAccounts$Params, context?: HttpContext): Observable<SyncPbxResultModel> {
    return this.pbxSyncSyncCustomerPbxAccounts$Response(params, context).pipe(
      map((r: StrictHttpResponse<SyncPbxResultModel>): SyncPbxResultModel => r.body)
    );
  }

  /** Path part for operation `pbxSyncSyncCustomerPbxAccount()` */
  static readonly PbxSyncSyncCustomerPbxAccountPath = '/api/pbx-sync/sync-customer-pbx-account';

  /**
   * Sync Customer Pbx data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxSyncSyncCustomerPbxAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxSyncSyncCustomerPbxAccount$Response(params: PbxSyncSyncCustomerPbxAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<SyncPbxResultModel>> {
    return pbxSyncSyncCustomerPbxAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * Sync Customer Pbx data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxSyncSyncCustomerPbxAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxSyncSyncCustomerPbxAccount(params: PbxSyncSyncCustomerPbxAccount$Params, context?: HttpContext): Observable<SyncPbxResultModel> {
    return this.pbxSyncSyncCustomerPbxAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<SyncPbxResultModel>): SyncPbxResultModel => r.body)
    );
  }

}
