/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PhoneNumberModel } from '../models/phone-number-model';
import { phoneNumbersEnableSmsNumberForUser } from '../fn/phone-numbers/phone-numbers-enable-sms-number-for-user';
import { PhoneNumbersEnableSmsNumberForUser$Params } from '../fn/phone-numbers/phone-numbers-enable-sms-number-for-user';
import { phoneNumbersExportExportPhoneNumbers } from '../fn/phone-numbers/phone-numbers-export-export-phone-numbers';
import { PhoneNumbersExportExportPhoneNumbers$Params } from '../fn/phone-numbers/phone-numbers-export-export-phone-numbers';
import { phoneNumbersGetPhoneNumberById } from '../fn/phone-numbers/phone-numbers-get-phone-number-by-id';
import { PhoneNumbersGetPhoneNumberById$Params } from '../fn/phone-numbers/phone-numbers-get-phone-number-by-id';
import { phoneNumbersGetPhoneNumberList } from '../fn/phone-numbers/phone-numbers-get-phone-number-list';
import { PhoneNumbersGetPhoneNumberList$Params } from '../fn/phone-numbers/phone-numbers-get-phone-number-list';
import { phoneNumbersGetSmsAvailablePhoneNumbers } from '../fn/phone-numbers/phone-numbers-get-sms-available-phone-numbers';
import { PhoneNumbersGetSmsAvailablePhoneNumbers$Params } from '../fn/phone-numbers/phone-numbers-get-sms-available-phone-numbers';
import { phoneNumbersGetSmsProcessingPhoneNumbers } from '../fn/phone-numbers/phone-numbers-get-sms-processing-phone-numbers';
import { PhoneNumbersGetSmsProcessingPhoneNumbers$Params } from '../fn/phone-numbers/phone-numbers-get-sms-processing-phone-numbers';
import { PhoneNumbersGridResponse } from '../models/phone-numbers-grid-response';
import { phoneNumbersSavePhoneNumbers } from '../fn/phone-numbers/phone-numbers-save-phone-numbers';
import { PhoneNumbersSavePhoneNumbers$Params } from '../fn/phone-numbers/phone-numbers-save-phone-numbers';
import { SmsAvailablePhoneNumbersResponse } from '../models/sms-available-phone-numbers-response';
import { SmsProcessingPhoneNumbersResponse } from '../models/sms-processing-phone-numbers-response';

@Injectable({ providedIn: 'root' })
export class PhoneNumbersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `phoneNumbersGetPhoneNumberList()` */
  static readonly PhoneNumbersGetPhoneNumberListPath = '/api/phone-numbers/list';

  /**
   * Get all account phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phoneNumbersGetPhoneNumberList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersGetPhoneNumberList$Response(params: PhoneNumbersGetPhoneNumberList$Params, context?: HttpContext): Observable<StrictHttpResponse<PhoneNumbersGridResponse>> {
    return phoneNumbersGetPhoneNumberList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all account phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phoneNumbersGetPhoneNumberList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersGetPhoneNumberList(params: PhoneNumbersGetPhoneNumberList$Params, context?: HttpContext): Observable<PhoneNumbersGridResponse> {
    return this.phoneNumbersGetPhoneNumberList$Response(params, context).pipe(
      map((r: StrictHttpResponse<PhoneNumbersGridResponse>): PhoneNumbersGridResponse => r.body)
    );
  }

  /** Path part for operation `phoneNumbersGetSmsAvailablePhoneNumbers()` */
  static readonly PhoneNumbersGetSmsAvailablePhoneNumbersPath = '/api/phone-numbers/sms-available-numbers';

  /**
   * Get all sms available phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phoneNumbersGetSmsAvailablePhoneNumbers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersGetSmsAvailablePhoneNumbers$Response(params: PhoneNumbersGetSmsAvailablePhoneNumbers$Params, context?: HttpContext): Observable<StrictHttpResponse<SmsAvailablePhoneNumbersResponse>> {
    return phoneNumbersGetSmsAvailablePhoneNumbers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all sms available phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phoneNumbersGetSmsAvailablePhoneNumbers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersGetSmsAvailablePhoneNumbers(params: PhoneNumbersGetSmsAvailablePhoneNumbers$Params, context?: HttpContext): Observable<SmsAvailablePhoneNumbersResponse> {
    return this.phoneNumbersGetSmsAvailablePhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<SmsAvailablePhoneNumbersResponse>): SmsAvailablePhoneNumbersResponse => r.body)
    );
  }

  /** Path part for operation `phoneNumbersGetPhoneNumberById()` */
  static readonly PhoneNumbersGetPhoneNumberByIdPath = '/api/phone-numbers/phone-number/{id}';

  /**
   * Get account phone number details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phoneNumbersGetPhoneNumberById()` instead.
   *
   * This method doesn't expect any request body.
   */
  phoneNumbersGetPhoneNumberById$Response(params: PhoneNumbersGetPhoneNumberById$Params, context?: HttpContext): Observable<StrictHttpResponse<PhoneNumberModel>> {
    return phoneNumbersGetPhoneNumberById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get account phone number details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phoneNumbersGetPhoneNumberById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  phoneNumbersGetPhoneNumberById(params: PhoneNumbersGetPhoneNumberById$Params, context?: HttpContext): Observable<PhoneNumberModel> {
    return this.phoneNumbersGetPhoneNumberById$Response(params, context).pipe(
      map((r: StrictHttpResponse<PhoneNumberModel>): PhoneNumberModel => r.body)
    );
  }

  /** Path part for operation `phoneNumbersSavePhoneNumbers()` */
  static readonly PhoneNumbersSavePhoneNumbersPath = '/api/phone-numbers/save-phone-numbers';

  /**
   * Save account phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phoneNumbersSavePhoneNumbers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersSavePhoneNumbers$Response(params: PhoneNumbersSavePhoneNumbers$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return phoneNumbersSavePhoneNumbers(this.http, this.rootUrl, params, context);
  }

  /**
   * Save account phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phoneNumbersSavePhoneNumbers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersSavePhoneNumbers(params: PhoneNumbersSavePhoneNumbers$Params, context?: HttpContext): Observable<void> {
    return this.phoneNumbersSavePhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `phoneNumbersEnableSmsNumberForUser()` */
  static readonly PhoneNumbersEnableSmsNumberForUserPath = '/api/phone-numbers/enable-sms-number';

  /**
   * Enable sms number for user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phoneNumbersEnableSmsNumberForUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersEnableSmsNumberForUser$Response(params: PhoneNumbersEnableSmsNumberForUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return phoneNumbersEnableSmsNumberForUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Enable sms number for user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phoneNumbersEnableSmsNumberForUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersEnableSmsNumberForUser(params: PhoneNumbersEnableSmsNumberForUser$Params, context?: HttpContext): Observable<void> {
    return this.phoneNumbersEnableSmsNumberForUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `phoneNumbersGetSmsProcessingPhoneNumbers()` */
  static readonly PhoneNumbersGetSmsProcessingPhoneNumbersPath = '/api/phone-numbers/sms-processing-numbers';

  /**
   * Get all sms processing phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phoneNumbersGetSmsProcessingPhoneNumbers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersGetSmsProcessingPhoneNumbers$Response(params: PhoneNumbersGetSmsProcessingPhoneNumbers$Params, context?: HttpContext): Observable<StrictHttpResponse<SmsProcessingPhoneNumbersResponse>> {
    return phoneNumbersGetSmsProcessingPhoneNumbers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all sms processing phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phoneNumbersGetSmsProcessingPhoneNumbers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersGetSmsProcessingPhoneNumbers(params: PhoneNumbersGetSmsProcessingPhoneNumbers$Params, context?: HttpContext): Observable<SmsProcessingPhoneNumbersResponse> {
    return this.phoneNumbersGetSmsProcessingPhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<SmsProcessingPhoneNumbersResponse>): SmsProcessingPhoneNumbersResponse => r.body)
    );
  }

  /** Path part for operation `phoneNumbersExportExportPhoneNumbers()` */
  static readonly PhoneNumbersExportExportPhoneNumbersPath = '/api/phone-numbers-export/export';

  /**
   * Export all account phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phoneNumbersExportExportPhoneNumbers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersExportExportPhoneNumbers$Response(params: PhoneNumbersExportExportPhoneNumbers$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return phoneNumbersExportExportPhoneNumbers(this.http, this.rootUrl, params, context);
  }

  /**
   * Export all account phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phoneNumbersExportExportPhoneNumbers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersExportExportPhoneNumbers(params: PhoneNumbersExportExportPhoneNumbers$Params, context?: HttpContext): Observable<string> {
    return this.phoneNumbersExportExportPhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
