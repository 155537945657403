<h1 mat-dialog-title>Update Available</h1>
<div mat-dialog-content class="mat-typography">
  <p>
    A new version of the application is available. Some functions may not work properly or may encounter issues until
    the page is reloaded.
  </p>

  <p>Application will refresh automatically in {{ countdown$ | async }} seconds.</p>
</div>
<div mat-dialog-actions align="end" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
  <button type="button" mat-button [mat-dialog-close]="false">Cancel</button>
  <button type="button" mat-button color="primary" [mat-dialog-close]="true" (click)="onReload()">Reload</button>
</div>
