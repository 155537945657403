/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AccountRoleModel } from '../models/account-role-model';
import { accountRolesDeleteRoleById } from '../fn/account/account-roles-delete-role-by-id';
import { AccountRolesDeleteRoleById$Params } from '../fn/account/account-roles-delete-role-by-id';
import { accountRolesGetRoleById } from '../fn/account/account-roles-get-role-by-id';
import { AccountRolesGetRoleById$Params } from '../fn/account/account-roles-get-role-by-id';
import { accountRolesGetRoles } from '../fn/account/account-roles-get-roles';
import { AccountRolesGetRoles$Params } from '../fn/account/account-roles-get-roles';
import { AccountRolesGridResponse } from '../models/account-roles-grid-response';
import { accountRolesSaveRole } from '../fn/account/account-roles-save-role';
import { AccountRolesSaveRole$Params } from '../fn/account/account-roles-save-role';
import { accountSettingsGetSettings } from '../fn/account/account-settings-get-settings';
import { AccountSettingsGetSettings$Params } from '../fn/account/account-settings-get-settings';
import { AccountSettingsModel } from '../models/account-settings-model';
import { accountSettingsPreviewFax } from '../fn/account/account-settings-preview-fax';
import { AccountSettingsPreviewFax$Params } from '../fn/account/account-settings-preview-fax';
import { accountSettingsSaveSettings } from '../fn/account/account-settings-save-settings';
import { AccountSettingsSaveSettings$Params } from '../fn/account/account-settings-save-settings';
import { accountSettingsToggleBeta } from '../fn/account/account-settings-toggle-beta';
import { AccountSettingsToggleBeta$Params } from '../fn/account/account-settings-toggle-beta';
import { accountStorageGetCurrentUsage } from '../fn/account/account-storage-get-current-usage';
import { AccountStorageGetCurrentUsage$Params } from '../fn/account/account-storage-get-current-usage';
import { accountStorageGetRetentionWarning } from '../fn/account/account-storage-get-retention-warning';
import { AccountStorageGetRetentionWarning$Params } from '../fn/account/account-storage-get-retention-warning';
import { AccountStorageModel } from '../models/account-storage-model';
import { accountStorageSaveRecordingsRetention } from '../fn/account/account-storage-save-recordings-retention';
import { AccountStorageSaveRecordingsRetention$Params } from '../fn/account/account-storage-save-recordings-retention';
import { AccountUser } from '../models/account-user';
import { accountUsersDeleteUserById } from '../fn/account/account-users-delete-user-by-id';
import { AccountUsersDeleteUserById$Params } from '../fn/account/account-users-delete-user-by-id';
import { accountUsersGetAccountDomains } from '../fn/account/account-users-get-account-domains';
import { AccountUsersGetAccountDomains$Params } from '../fn/account/account-users-get-account-domains';
import { accountUsersGetRoles } from '../fn/account/account-users-get-roles';
import { AccountUsersGetRoles$Params } from '../fn/account/account-users-get-roles';
import { accountUsersGetUserById } from '../fn/account/account-users-get-user-by-id';
import { AccountUsersGetUserById$Params } from '../fn/account/account-users-get-user-by-id';
import { accountUsersGetUsers } from '../fn/account/account-users-get-users';
import { AccountUsersGetUsers$Params } from '../fn/account/account-users-get-users';
import { AccountUsersGridResponse } from '../models/account-users-grid-response';
import { accountUsersResendInvitation } from '../fn/account/account-users-resend-invitation';
import { AccountUsersResendInvitation$Params } from '../fn/account/account-users-resend-invitation';
import { accountUsersSaveUser } from '../fn/account/account-users-save-user';
import { AccountUsersSaveUser$Params } from '../fn/account/account-users-save-user';
import { LookupGridResponse } from '../models/lookup-grid-response';
import { RetentionWarningModel } from '../models/retention-warning-model';
import { ToggleBetaResponse } from '../models/toggle-beta-response';

@Injectable({ providedIn: 'root' })
export class AccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `accountUsersGetRoles()` */
  static readonly AccountUsersGetRolesPath = '/api/account/roles';

  /**
   * Get available roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersGetRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetRoles$Response(params?: AccountUsersGetRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<LookupGridResponse>> {
    return accountUsersGetRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get available roles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersGetRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetRoles(params?: AccountUsersGetRoles$Params, context?: HttpContext): Observable<LookupGridResponse> {
    return this.accountUsersGetRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupGridResponse>): LookupGridResponse => r.body)
    );
  }

  /** Path part for operation `accountRolesGetRoles()` */
  static readonly AccountRolesGetRolesPath = '/api/account/roles';

  /**
   * Get all account roles.
   *
   * Get the list of company roles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountRolesGetRoles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountRolesGetRoles$Response(params: AccountRolesGetRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountRolesGridResponse>> {
    return accountRolesGetRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all account roles.
   *
   * Get the list of company roles
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountRolesGetRoles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountRolesGetRoles(params: AccountRolesGetRoles$Params, context?: HttpContext): Observable<AccountRolesGridResponse> {
    return this.accountRolesGetRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountRolesGridResponse>): AccountRolesGridResponse => r.body)
    );
  }

  /** Path part for operation `accountUsersGetUsers()` */
  static readonly AccountUsersGetUsersPath = '/api/account/users';

  /**
   * Get all account users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersGetUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountUsersGetUsers$Response(params: AccountUsersGetUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountUsersGridResponse>> {
    return accountUsersGetUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all account users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersGetUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountUsersGetUsers(params: AccountUsersGetUsers$Params, context?: HttpContext): Observable<AccountUsersGridResponse> {
    return this.accountUsersGetUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountUsersGridResponse>): AccountUsersGridResponse => r.body)
    );
  }

  /** Path part for operation `accountUsersGetUserById()` */
  static readonly AccountUsersGetUserByIdPath = '/api/account/user/{id}';

  /**
   * Get account user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersGetUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetUserById$Response(params: AccountUsersGetUserById$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountUser>> {
    return accountUsersGetUserById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get account user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersGetUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetUserById(params: AccountUsersGetUserById$Params, context?: HttpContext): Observable<AccountUser> {
    return this.accountUsersGetUserById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountUser>): AccountUser => r.body)
    );
  }

  /** Path part for operation `accountUsersDeleteUserById()` */
  static readonly AccountUsersDeleteUserByIdPath = '/api/account/user/{id}';

  /**
   * Delete account user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersDeleteUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersDeleteUserById$Response(params: AccountUsersDeleteUserById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return accountUsersDeleteUserById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete account user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersDeleteUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersDeleteUserById(params: AccountUsersDeleteUserById$Params, context?: HttpContext): Observable<void> {
    return this.accountUsersDeleteUserById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountUsersSaveUser()` */
  static readonly AccountUsersSaveUserPath = '/api/account/user';

  /**
   * Save user details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersSaveUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountUsersSaveUser$Response(params: AccountUsersSaveUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return accountUsersSaveUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Save user details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersSaveUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountUsersSaveUser(params: AccountUsersSaveUser$Params, context?: HttpContext): Observable<void> {
    return this.accountUsersSaveUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountUsersResendInvitation()` */
  static readonly AccountUsersResendInvitationPath = '/api/account/resend-invitation/{email}';

  /**
   * Resend invitation email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersResendInvitation()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersResendInvitation$Response(params: AccountUsersResendInvitation$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return accountUsersResendInvitation(this.http, this.rootUrl, params, context);
  }

  /**
   * Resend invitation email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersResendInvitation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersResendInvitation(params: AccountUsersResendInvitation$Params, context?: HttpContext): Observable<boolean> {
    return this.accountUsersResendInvitation$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `accountUsersGetAccountDomains()` */
  static readonly AccountUsersGetAccountDomainsPath = '/api/account/domains';

  /**
   * Get all account domain.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersGetAccountDomains()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetAccountDomains$Response(params?: AccountUsersGetAccountDomains$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return accountUsersGetAccountDomains(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all account domain.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersGetAccountDomains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetAccountDomains(params?: AccountUsersGetAccountDomains$Params, context?: HttpContext): Observable<Array<string>> {
    return this.accountUsersGetAccountDomains$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `accountRolesGetRoleById()` */
  static readonly AccountRolesGetRoleByIdPath = '/api/account/role/{id}';

  /**
   * Get account role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountRolesGetRoleById()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountRolesGetRoleById$Response(params: AccountRolesGetRoleById$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountRoleModel>> {
    return accountRolesGetRoleById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get account role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountRolesGetRoleById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountRolesGetRoleById(params: AccountRolesGetRoleById$Params, context?: HttpContext): Observable<AccountRoleModel> {
    return this.accountRolesGetRoleById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountRoleModel>): AccountRoleModel => r.body)
    );
  }

  /** Path part for operation `accountRolesDeleteRoleById()` */
  static readonly AccountRolesDeleteRoleByIdPath = '/api/account/role/{id}';

  /**
   * Delete account role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountRolesDeleteRoleById()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountRolesDeleteRoleById$Response(params: AccountRolesDeleteRoleById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return accountRolesDeleteRoleById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete account role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountRolesDeleteRoleById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountRolesDeleteRoleById(params: AccountRolesDeleteRoleById$Params, context?: HttpContext): Observable<void> {
    return this.accountRolesDeleteRoleById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountRolesSaveRole()` */
  static readonly AccountRolesSaveRolePath = '/api/account/role';

  /**
   * Save role details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountRolesSaveRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountRolesSaveRole$Response(params: AccountRolesSaveRole$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return accountRolesSaveRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Save role details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountRolesSaveRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountRolesSaveRole(params: AccountRolesSaveRole$Params, context?: HttpContext): Observable<void> {
    return this.accountRolesSaveRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountSettingsGetSettings()` */
  static readonly AccountSettingsGetSettingsPath = '/api/account/settings';

  /**
   * Get account settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSettingsGetSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountSettingsGetSettings$Response(params?: AccountSettingsGetSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountSettingsModel>> {
    return accountSettingsGetSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Get account settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSettingsGetSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountSettingsGetSettings(params?: AccountSettingsGetSettings$Params, context?: HttpContext): Observable<AccountSettingsModel> {
    return this.accountSettingsGetSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountSettingsModel>): AccountSettingsModel => r.body)
    );
  }

  /** Path part for operation `accountSettingsSaveSettings()` */
  static readonly AccountSettingsSaveSettingsPath = '/api/account/settings';

  /**
   * Save account settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSettingsSaveSettings()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  accountSettingsSaveSettings$Response(params: AccountSettingsSaveSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountSettingsModel>> {
    return accountSettingsSaveSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Save account settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSettingsSaveSettings$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  accountSettingsSaveSettings(params: AccountSettingsSaveSettings$Params, context?: HttpContext): Observable<AccountSettingsModel> {
    return this.accountSettingsSaveSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountSettingsModel>): AccountSettingsModel => r.body)
    );
  }

  /** Path part for operation `accountSettingsPreviewFax()` */
  static readonly AccountSettingsPreviewFaxPath = '/api/account/preview-fax-cover/{filename}';

  /**
   * Preview Fax Cover Page.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSettingsPreviewFax()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountSettingsPreviewFax$Response(params: AccountSettingsPreviewFax$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return accountSettingsPreviewFax(this.http, this.rootUrl, params, context);
  }

  /**
   * Preview Fax Cover Page.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSettingsPreviewFax$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountSettingsPreviewFax(params: AccountSettingsPreviewFax$Params, context?: HttpContext): Observable<void> {
    return this.accountSettingsPreviewFax$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountSettingsToggleBeta()` */
  static readonly AccountSettingsToggleBetaPath = '/api/account/toggle-beta';

  /**
   * Become beta tester or switch to stable.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSettingsToggleBeta()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountSettingsToggleBeta$Response(params: AccountSettingsToggleBeta$Params, context?: HttpContext): Observable<StrictHttpResponse<ToggleBetaResponse>> {
    return accountSettingsToggleBeta(this.http, this.rootUrl, params, context);
  }

  /**
   * Become beta tester or switch to stable.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSettingsToggleBeta$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountSettingsToggleBeta(params: AccountSettingsToggleBeta$Params, context?: HttpContext): Observable<ToggleBetaResponse> {
    return this.accountSettingsToggleBeta$Response(params, context).pipe(
      map((r: StrictHttpResponse<ToggleBetaResponse>): ToggleBetaResponse => r.body)
    );
  }

  /** Path part for operation `accountStorageGetCurrentUsage()` */
  static readonly AccountStorageGetCurrentUsagePath = '/api/account/current-usage';

  /**
   * Get current storage usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountStorageGetCurrentUsage()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountStorageGetCurrentUsage$Response(params?: AccountStorageGetCurrentUsage$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountStorageModel>> {
    return accountStorageGetCurrentUsage(this.http, this.rootUrl, params, context);
  }

  /**
   * Get current storage usage.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountStorageGetCurrentUsage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountStorageGetCurrentUsage(params?: AccountStorageGetCurrentUsage$Params, context?: HttpContext): Observable<AccountStorageModel> {
    return this.accountStorageGetCurrentUsage$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountStorageModel>): AccountStorageModel => r.body)
    );
  }

  /** Path part for operation `accountStorageSaveRecordingsRetention()` */
  static readonly AccountStorageSaveRecordingsRetentionPath = '/api/account/save-recordings-retention';

  /**
   * Save account recordings retention limits.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountStorageSaveRecordingsRetention()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountStorageSaveRecordingsRetention$Response(params: AccountStorageSaveRecordingsRetention$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountStorageModel>> {
    return accountStorageSaveRecordingsRetention(this.http, this.rootUrl, params, context);
  }

  /**
   * Save account recordings retention limits.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountStorageSaveRecordingsRetention$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountStorageSaveRecordingsRetention(params: AccountStorageSaveRecordingsRetention$Params, context?: HttpContext): Observable<AccountStorageModel> {
    return this.accountStorageSaveRecordingsRetention$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountStorageModel>): AccountStorageModel => r.body)
    );
  }

  /** Path part for operation `accountStorageGetRetentionWarning()` */
  static readonly AccountStorageGetRetentionWarningPath = '/api/account/recordings-retention-warning';

  /**
   * Get hours that will be deleted after plan changes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountStorageGetRetentionWarning()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountStorageGetRetentionWarning$Response(params: AccountStorageGetRetentionWarning$Params, context?: HttpContext): Observable<StrictHttpResponse<RetentionWarningModel>> {
    return accountStorageGetRetentionWarning(this.http, this.rootUrl, params, context);
  }

  /**
   * Get hours that will be deleted after plan changes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountStorageGetRetentionWarning$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountStorageGetRetentionWarning(params: AccountStorageGetRetentionWarning$Params, context?: HttpContext): Observable<RetentionWarningModel> {
    return this.accountStorageGetRetentionWarning$Response(params, context).pipe(
      map((r: StrictHttpResponse<RetentionWarningModel>): RetentionWarningModel => r.body)
    );
  }

}
