/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { GroupAgentsSummaryResponse } from '../models/group-agents-summary-response';
import { GroupsSummaryResponse } from '../models/groups-summary-response';
import { groupsSummaryV2GetAgentsSummaryData } from '../fn/reports-v-2/groups-summary-v-2-get-agents-summary-data';
import { GroupsSummaryV2GetAgentsSummaryData$Params } from '../fn/reports-v-2/groups-summary-v-2-get-agents-summary-data';
import { groupsSummaryV2GetExport } from '../fn/reports-v-2/groups-summary-v-2-get-export';
import { GroupsSummaryV2GetExport$Params } from '../fn/reports-v-2/groups-summary-v-2-get-export';
import { groupsSummaryV2GetExportAgents } from '../fn/reports-v-2/groups-summary-v-2-get-export-agents';
import { GroupsSummaryV2GetExportAgents$Params } from '../fn/reports-v-2/groups-summary-v-2-get-export-agents';
import { groupsSummaryV2GetSummaryData } from '../fn/reports-v-2/groups-summary-v-2-get-summary-data';
import { GroupsSummaryV2GetSummaryData$Params } from '../fn/reports-v-2/groups-summary-v-2-get-summary-data';

@Injectable({ providedIn: 'root' })
export class GroupsReportsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `groupsSummaryV2GetSummaryData()` */
  static readonly GroupsSummaryV2GetSummaryDataPath = '/api/reports-v2/groups-summary-data';

  /**
   * Get groups summary data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryV2GetSummaryData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetSummaryData$Response(params: GroupsSummaryV2GetSummaryData$Params, context?: HttpContext): Observable<StrictHttpResponse<GroupsSummaryResponse>> {
    return groupsSummaryV2GetSummaryData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get groups summary data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryV2GetSummaryData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetSummaryData(params: GroupsSummaryV2GetSummaryData$Params, context?: HttpContext): Observable<GroupsSummaryResponse> {
    return this.groupsSummaryV2GetSummaryData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupsSummaryResponse>): GroupsSummaryResponse => r.body)
    );
  }

  /** Path part for operation `groupsSummaryV2GetAgentsSummaryData()` */
  static readonly GroupsSummaryV2GetAgentsSummaryDataPath = '/api/reports-v2/groups-agent-summary-data';

  /**
   * Get groups agents summary data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryV2GetAgentsSummaryData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetAgentsSummaryData$Response(params: GroupsSummaryV2GetAgentsSummaryData$Params, context?: HttpContext): Observable<StrictHttpResponse<GroupAgentsSummaryResponse>> {
    return groupsSummaryV2GetAgentsSummaryData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get groups agents summary data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryV2GetAgentsSummaryData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetAgentsSummaryData(params: GroupsSummaryV2GetAgentsSummaryData$Params, context?: HttpContext): Observable<GroupAgentsSummaryResponse> {
    return this.groupsSummaryV2GetAgentsSummaryData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupAgentsSummaryResponse>): GroupAgentsSummaryResponse => r.body)
    );
  }

  /** Path part for operation `groupsSummaryV2GetExport()` */
  static readonly GroupsSummaryV2GetExportPath = '/api/reports-v2/groups-summary/export';

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryV2GetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetExport$Response(params: GroupsSummaryV2GetExport$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return groupsSummaryV2GetExport(this.http, this.rootUrl, params, context);
  }

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryV2GetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetExport(params: GroupsSummaryV2GetExport$Params, context?: HttpContext): Observable<string> {
    return this.groupsSummaryV2GetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `groupsSummaryV2GetExportAgents()` */
  static readonly GroupsSummaryV2GetExportAgentsPath = '/api/reports-v2/groups-summary/export-agents';

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryV2GetExportAgents()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetExportAgents$Response(params: GroupsSummaryV2GetExportAgents$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return groupsSummaryV2GetExportAgents(this.http, this.rootUrl, params, context);
  }

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryV2GetExportAgents$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetExportAgents(params: GroupsSummaryV2GetExportAgents$Params, context?: HttpContext): Observable<string> {
    return this.groupsSummaryV2GetExportAgents$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
