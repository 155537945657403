/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CustomFieldDefinitionsRequest } from '../../models/custom-field-definitions-request';
import { CustomFieldModel } from '../../models/custom-field-model';

export interface ContactGetCustomFieldsDefinitions$Params {
      body: CustomFieldDefinitionsRequest
}

export function contactGetCustomFieldsDefinitions(http: HttpClient, rootUrl: string, params: ContactGetCustomFieldsDefinitions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomFieldModel>>> {
  const rb = new RequestBuilder(rootUrl, contactGetCustomFieldsDefinitions.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<CustomFieldModel>>;
    })
  );
}

contactGetCustomFieldsDefinitions.PATH = '/api/contact/custom-fields';
