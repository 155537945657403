/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CloudFileModel } from '../models/cloud-file-model';
import { HolidayGreetingsGridResponse } from '../models/holiday-greetings-grid-response';
import { HolidayGreetingsModel } from '../models/holiday-greetings-model';
import { LookupModel } from '../models/lookup-model';
import { pbxHolidayGreetingsDownloadRecording } from '../fn/holiday-greetings/pbx-holiday-greetings-download-recording';
import { PbxHolidayGreetingsDownloadRecording$Params } from '../fn/holiday-greetings/pbx-holiday-greetings-download-recording';
import { pbxHolidayGreetingsGetAvailableHolidayGreetings } from '../fn/holiday-greetings/pbx-holiday-greetings-get-available-holiday-greetings';
import { PbxHolidayGreetingsGetAvailableHolidayGreetings$Params } from '../fn/holiday-greetings/pbx-holiday-greetings-get-available-holiday-greetings';
import { pbxHolidayGreetingsGetHolidayGreetingsDetails } from '../fn/holiday-greetings/pbx-holiday-greetings-get-holiday-greetings-details';
import { PbxHolidayGreetingsGetHolidayGreetingsDetails$Params } from '../fn/holiday-greetings/pbx-holiday-greetings-get-holiday-greetings-details';
import { pbxHolidayGreetingsGetList } from '../fn/holiday-greetings/pbx-holiday-greetings-get-list';
import { PbxHolidayGreetingsGetList$Params } from '../fn/holiday-greetings/pbx-holiday-greetings-get-list';
import { pbxHolidayGreetingsGetTextToSpeechAudioStream } from '../fn/holiday-greetings/pbx-holiday-greetings-get-text-to-speech-audio-stream';
import { PbxHolidayGreetingsGetTextToSpeechAudioStream$Params } from '../fn/holiday-greetings/pbx-holiday-greetings-get-text-to-speech-audio-stream';
import { pbxHolidayGreetingsGetTextToSpeechOptions } from '../fn/holiday-greetings/pbx-holiday-greetings-get-text-to-speech-options';
import { PbxHolidayGreetingsGetTextToSpeechOptions$Params } from '../fn/holiday-greetings/pbx-holiday-greetings-get-text-to-speech-options';
import { pbxHolidayGreetingsSaveHolidayGreetingDetails } from '../fn/holiday-greetings/pbx-holiday-greetings-save-holiday-greeting-details';
import { PbxHolidayGreetingsSaveHolidayGreetingDetails$Params } from '../fn/holiday-greetings/pbx-holiday-greetings-save-holiday-greeting-details';
import { pbxHolidayGreetingsStreamPublicRecording } from '../fn/holiday-greetings/pbx-holiday-greetings-stream-public-recording';
import { PbxHolidayGreetingsStreamPublicRecording$Params } from '../fn/holiday-greetings/pbx-holiday-greetings-stream-public-recording';
import { pbxHolidayGreetingsStreamRecording } from '../fn/holiday-greetings/pbx-holiday-greetings-stream-recording';
import { PbxHolidayGreetingsStreamRecording$Params } from '../fn/holiday-greetings/pbx-holiday-greetings-stream-recording';
import { TextToSpeechResponse } from '../models/text-to-speech-response';

@Injectable({ providedIn: 'root' })
export class HolidayGreetingsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `pbxHolidayGreetingsGetList()` */
  static readonly PbxHolidayGreetingsGetListPath = '/api/holiday-greetings/list';

  /**
   * Get List Of Holiday Greetings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxHolidayGreetingsGetList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxHolidayGreetingsGetList$Response(params: PbxHolidayGreetingsGetList$Params, context?: HttpContext): Observable<StrictHttpResponse<HolidayGreetingsGridResponse>> {
    return pbxHolidayGreetingsGetList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get List Of Holiday Greetings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxHolidayGreetingsGetList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxHolidayGreetingsGetList(params: PbxHolidayGreetingsGetList$Params, context?: HttpContext): Observable<HolidayGreetingsGridResponse> {
    return this.pbxHolidayGreetingsGetList$Response(params, context).pipe(
      map((r: StrictHttpResponse<HolidayGreetingsGridResponse>): HolidayGreetingsGridResponse => r.body)
    );
  }

  /** Path part for operation `pbxHolidayGreetingsGetHolidayGreetingsDetails()` */
  static readonly PbxHolidayGreetingsGetHolidayGreetingsDetailsPath = '/api/holiday-greetings/get/{account}';

  /**
   * Get Holiday Greetings Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxHolidayGreetingsGetHolidayGreetingsDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsGetHolidayGreetingsDetails$Response(params: PbxHolidayGreetingsGetHolidayGreetingsDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<HolidayGreetingsModel>> {
    return pbxHolidayGreetingsGetHolidayGreetingsDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Holiday Greetings Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxHolidayGreetingsGetHolidayGreetingsDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsGetHolidayGreetingsDetails(params: PbxHolidayGreetingsGetHolidayGreetingsDetails$Params, context?: HttpContext): Observable<HolidayGreetingsModel> {
    return this.pbxHolidayGreetingsGetHolidayGreetingsDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<HolidayGreetingsModel>): HolidayGreetingsModel => r.body)
    );
  }

  /** Path part for operation `pbxHolidayGreetingsSaveHolidayGreetingDetails()` */
  static readonly PbxHolidayGreetingsSaveHolidayGreetingDetailsPath = '/api/holiday-greetings/save';

  /**
   * Save Holiday Greetings Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxHolidayGreetingsSaveHolidayGreetingDetails()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  pbxHolidayGreetingsSaveHolidayGreetingDetails$Response(params: PbxHolidayGreetingsSaveHolidayGreetingDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return pbxHolidayGreetingsSaveHolidayGreetingDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Save Holiday Greetings Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxHolidayGreetingsSaveHolidayGreetingDetails$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  pbxHolidayGreetingsSaveHolidayGreetingDetails(params: PbxHolidayGreetingsSaveHolidayGreetingDetails$Params, context?: HttpContext): Observable<void> {
    return this.pbxHolidayGreetingsSaveHolidayGreetingDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `pbxHolidayGreetingsStreamRecording()` */
  static readonly PbxHolidayGreetingsStreamRecordingPath = '/api/holiday-greetings/audio/stream/{account}';

  /**
   * Stream Holiday Greetings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxHolidayGreetingsStreamRecording()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsStreamRecording$Response(params: PbxHolidayGreetingsStreamRecording$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return pbxHolidayGreetingsStreamRecording(this.http, this.rootUrl, params, context);
  }

  /**
   * Stream Holiday Greetings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxHolidayGreetingsStreamRecording$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsStreamRecording(params: PbxHolidayGreetingsStreamRecording$Params, context?: HttpContext): Observable<void> {
    return this.pbxHolidayGreetingsStreamRecording$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `pbxHolidayGreetingsDownloadRecording()` */
  static readonly PbxHolidayGreetingsDownloadRecordingPath = '/api/holiday-greetings/audio/download/{account}';

  /**
   * Download Holiday Greetings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxHolidayGreetingsDownloadRecording()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsDownloadRecording$Response(params: PbxHolidayGreetingsDownloadRecording$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return pbxHolidayGreetingsDownloadRecording(this.http, this.rootUrl, params, context);
  }

  /**
   * Download Holiday Greetings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxHolidayGreetingsDownloadRecording$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsDownloadRecording(params: PbxHolidayGreetingsDownloadRecording$Params, context?: HttpContext): Observable<void> {
    return this.pbxHolidayGreetingsDownloadRecording$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `pbxHolidayGreetingsGetAvailableHolidayGreetings()` */
  static readonly PbxHolidayGreetingsGetAvailableHolidayGreetingsPath = '/api/holiday-greetings/available-holiday-greetings';

  /**
   * Get list of available Holiday Greetings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxHolidayGreetingsGetAvailableHolidayGreetings()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsGetAvailableHolidayGreetings$Response(params?: PbxHolidayGreetingsGetAvailableHolidayGreetings$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CloudFileModel>>> {
    return pbxHolidayGreetingsGetAvailableHolidayGreetings(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of available Holiday Greetings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxHolidayGreetingsGetAvailableHolidayGreetings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsGetAvailableHolidayGreetings(params?: PbxHolidayGreetingsGetAvailableHolidayGreetings$Params, context?: HttpContext): Observable<Array<CloudFileModel>> {
    return this.pbxHolidayGreetingsGetAvailableHolidayGreetings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CloudFileModel>>): Array<CloudFileModel> => r.body)
    );
  }

  /** Path part for operation `pbxHolidayGreetingsStreamPublicRecording()` */
  static readonly PbxHolidayGreetingsStreamPublicRecordingPath = '/api/holiday-greetings/pre-audio/stream/{id}';

  /**
   * Stream Predefined Holiday Greetings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxHolidayGreetingsStreamPublicRecording()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsStreamPublicRecording$Response(params: PbxHolidayGreetingsStreamPublicRecording$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return pbxHolidayGreetingsStreamPublicRecording(this.http, this.rootUrl, params, context);
  }

  /**
   * Stream Predefined Holiday Greetings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxHolidayGreetingsStreamPublicRecording$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsStreamPublicRecording(params: PbxHolidayGreetingsStreamPublicRecording$Params, context?: HttpContext): Observable<void> {
    return this.pbxHolidayGreetingsStreamPublicRecording$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `pbxHolidayGreetingsGetTextToSpeechOptions()` */
  static readonly PbxHolidayGreetingsGetTextToSpeechOptionsPath = '/api/holiday-greetings/text-to-speech-options';

  /**
   * Get Text To Speech Options.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxHolidayGreetingsGetTextToSpeechOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsGetTextToSpeechOptions$Response(params?: PbxHolidayGreetingsGetTextToSpeechOptions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LookupModel>>> {
    return pbxHolidayGreetingsGetTextToSpeechOptions(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Text To Speech Options.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxHolidayGreetingsGetTextToSpeechOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsGetTextToSpeechOptions(params?: PbxHolidayGreetingsGetTextToSpeechOptions$Params, context?: HttpContext): Observable<Array<LookupModel>> {
    return this.pbxHolidayGreetingsGetTextToSpeechOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LookupModel>>): Array<LookupModel> => r.body)
    );
  }

  /** Path part for operation `pbxHolidayGreetingsGetTextToSpeechAudioStream()` */
  static readonly PbxHolidayGreetingsGetTextToSpeechAudioStreamPath = '/api/holiday-greetings/text-to-speech';

  /**
   * Get Text To Speech Audio Data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxHolidayGreetingsGetTextToSpeechAudioStream()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxHolidayGreetingsGetTextToSpeechAudioStream$Response(params: PbxHolidayGreetingsGetTextToSpeechAudioStream$Params, context?: HttpContext): Observable<StrictHttpResponse<TextToSpeechResponse>> {
    return pbxHolidayGreetingsGetTextToSpeechAudioStream(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Text To Speech Audio Data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxHolidayGreetingsGetTextToSpeechAudioStream$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxHolidayGreetingsGetTextToSpeechAudioStream(params: PbxHolidayGreetingsGetTextToSpeechAudioStream$Params, context?: HttpContext): Observable<TextToSpeechResponse> {
    return this.pbxHolidayGreetingsGetTextToSpeechAudioStream$Response(params, context).pipe(
      map((r: StrictHttpResponse<TextToSpeechResponse>): TextToSpeechResponse => r.body)
    );
  }

}
