/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AccountUser } from '../models/account-user';
import { accountUsersDeleteUserById } from '../fn/account/account-users-delete-user-by-id';
import { AccountUsersDeleteUserById$Params } from '../fn/account/account-users-delete-user-by-id';
import { accountUsersGetAccountDomains } from '../fn/account/account-users-get-account-domains';
import { AccountUsersGetAccountDomains$Params } from '../fn/account/account-users-get-account-domains';
import { accountUsersGetRoles } from '../fn/account/account-users-get-roles';
import { AccountUsersGetRoles$Params } from '../fn/account/account-users-get-roles';
import { accountUsersGetUserById } from '../fn/account/account-users-get-user-by-id';
import { AccountUsersGetUserById$Params } from '../fn/account/account-users-get-user-by-id';
import { accountUsersGetUsers } from '../fn/account/account-users-get-users';
import { AccountUsersGetUsers$Params } from '../fn/account/account-users-get-users';
import { AccountUsersGridResponse } from '../models/account-users-grid-response';
import { accountUsersResendInvitation } from '../fn/account/account-users-resend-invitation';
import { AccountUsersResendInvitation$Params } from '../fn/account/account-users-resend-invitation';
import { accountUsersSaveUser } from '../fn/account/account-users-save-user';
import { AccountUsersSaveUser$Params } from '../fn/account/account-users-save-user';
import { LookupGridResponse } from '../models/lookup-grid-response';

@Injectable({ providedIn: 'root' })
export class UsersManagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `accountUsersGetRoles()` */
  static readonly AccountUsersGetRolesPath = '/api/account/roles';

  /**
   * Get available roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersGetRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetRoles$Response(params?: AccountUsersGetRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<LookupGridResponse>> {
    return accountUsersGetRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get available roles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersGetRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetRoles(params?: AccountUsersGetRoles$Params, context?: HttpContext): Observable<LookupGridResponse> {
    return this.accountUsersGetRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupGridResponse>): LookupGridResponse => r.body)
    );
  }

  /** Path part for operation `accountUsersGetUsers()` */
  static readonly AccountUsersGetUsersPath = '/api/account/users';

  /**
   * Get all account users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersGetUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountUsersGetUsers$Response(params: AccountUsersGetUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountUsersGridResponse>> {
    return accountUsersGetUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all account users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersGetUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountUsersGetUsers(params: AccountUsersGetUsers$Params, context?: HttpContext): Observable<AccountUsersGridResponse> {
    return this.accountUsersGetUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountUsersGridResponse>): AccountUsersGridResponse => r.body)
    );
  }

  /** Path part for operation `accountUsersGetUserById()` */
  static readonly AccountUsersGetUserByIdPath = '/api/account/user/{id}';

  /**
   * Get account user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersGetUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetUserById$Response(params: AccountUsersGetUserById$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountUser>> {
    return accountUsersGetUserById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get account user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersGetUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetUserById(params: AccountUsersGetUserById$Params, context?: HttpContext): Observable<AccountUser> {
    return this.accountUsersGetUserById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountUser>): AccountUser => r.body)
    );
  }

  /** Path part for operation `accountUsersDeleteUserById()` */
  static readonly AccountUsersDeleteUserByIdPath = '/api/account/user/{id}';

  /**
   * Delete account user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersDeleteUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersDeleteUserById$Response(params: AccountUsersDeleteUserById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return accountUsersDeleteUserById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete account user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersDeleteUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersDeleteUserById(params: AccountUsersDeleteUserById$Params, context?: HttpContext): Observable<void> {
    return this.accountUsersDeleteUserById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountUsersSaveUser()` */
  static readonly AccountUsersSaveUserPath = '/api/account/user';

  /**
   * Save user details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersSaveUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountUsersSaveUser$Response(params: AccountUsersSaveUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return accountUsersSaveUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Save user details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersSaveUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountUsersSaveUser(params: AccountUsersSaveUser$Params, context?: HttpContext): Observable<void> {
    return this.accountUsersSaveUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountUsersResendInvitation()` */
  static readonly AccountUsersResendInvitationPath = '/api/account/resend-invitation/{email}';

  /**
   * Resend invitation email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersResendInvitation()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersResendInvitation$Response(params: AccountUsersResendInvitation$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return accountUsersResendInvitation(this.http, this.rootUrl, params, context);
  }

  /**
   * Resend invitation email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersResendInvitation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersResendInvitation(params: AccountUsersResendInvitation$Params, context?: HttpContext): Observable<boolean> {
    return this.accountUsersResendInvitation$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `accountUsersGetAccountDomains()` */
  static readonly AccountUsersGetAccountDomainsPath = '/api/account/domains';

  /**
   * Get all account domain.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersGetAccountDomains()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetAccountDomains$Response(params?: AccountUsersGetAccountDomains$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return accountUsersGetAccountDomains(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all account domain.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersGetAccountDomains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetAccountDomains(params?: AccountUsersGetAccountDomains$Params, context?: HttpContext): Observable<Array<string>> {
    return this.accountUsersGetAccountDomains$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
