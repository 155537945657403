/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CustomFieldGridResponse } from '../models/custom-field-grid-response';
import { CustomFieldModel } from '../models/custom-field-model';
import { customFieldsDeleteCustomFieldById } from '../fn/custom-fields/custom-fields-delete-custom-field-by-id';
import { CustomFieldsDeleteCustomFieldById$Params } from '../fn/custom-fields/custom-fields-delete-custom-field-by-id';
import { customFieldsGetCustomFieldById } from '../fn/custom-fields/custom-fields-get-custom-field-by-id';
import { CustomFieldsGetCustomFieldById$Params } from '../fn/custom-fields/custom-fields-get-custom-field-by-id';
import { customFieldsGetCustomFieldList } from '../fn/custom-fields/custom-fields-get-custom-field-list';
import { CustomFieldsGetCustomFieldList$Params } from '../fn/custom-fields/custom-fields-get-custom-field-list';
import { customFieldsSaveCustomField } from '../fn/custom-fields/custom-fields-save-custom-field';
import { CustomFieldsSaveCustomField$Params } from '../fn/custom-fields/custom-fields-save-custom-field';

@Injectable({ providedIn: 'root' })
export class CustomFieldsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `customFieldsGetCustomFieldList()` */
  static readonly CustomFieldsGetCustomFieldListPath = '/api/custom-fields/list';

  /**
   * Get list of custom fields.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customFieldsGetCustomFieldList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customFieldsGetCustomFieldList$Response(params: CustomFieldsGetCustomFieldList$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomFieldGridResponse>> {
    return customFieldsGetCustomFieldList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of custom fields.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customFieldsGetCustomFieldList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customFieldsGetCustomFieldList(params: CustomFieldsGetCustomFieldList$Params, context?: HttpContext): Observable<CustomFieldGridResponse> {
    return this.customFieldsGetCustomFieldList$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomFieldGridResponse>): CustomFieldGridResponse => r.body)
    );
  }

  /** Path part for operation `customFieldsGetCustomFieldById()` */
  static readonly CustomFieldsGetCustomFieldByIdPath = '/api/custom-fields/{id}';

  /**
   * Get account ip address details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customFieldsGetCustomFieldById()` instead.
   *
   * This method doesn't expect any request body.
   */
  customFieldsGetCustomFieldById$Response(params: CustomFieldsGetCustomFieldById$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomFieldModel>> {
    return customFieldsGetCustomFieldById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get account ip address details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customFieldsGetCustomFieldById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customFieldsGetCustomFieldById(params: CustomFieldsGetCustomFieldById$Params, context?: HttpContext): Observable<CustomFieldModel> {
    return this.customFieldsGetCustomFieldById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomFieldModel>): CustomFieldModel => r.body)
    );
  }

  /** Path part for operation `customFieldsDeleteCustomFieldById()` */
  static readonly CustomFieldsDeleteCustomFieldByIdPath = '/api/custom-fields/{id}';

  /**
   * Delete account ip address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customFieldsDeleteCustomFieldById()` instead.
   *
   * This method doesn't expect any request body.
   */
  customFieldsDeleteCustomFieldById$Response(params: CustomFieldsDeleteCustomFieldById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return customFieldsDeleteCustomFieldById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete account ip address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customFieldsDeleteCustomFieldById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customFieldsDeleteCustomFieldById(params: CustomFieldsDeleteCustomFieldById$Params, context?: HttpContext): Observable<void> {
    return this.customFieldsDeleteCustomFieldById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `customFieldsSaveCustomField()` */
  static readonly CustomFieldsSaveCustomFieldPath = '/api/custom-fields/save';

  /**
   * Save custom field.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customFieldsSaveCustomField()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customFieldsSaveCustomField$Response(params: CustomFieldsSaveCustomField$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return customFieldsSaveCustomField(this.http, this.rootUrl, params, context);
  }

  /**
   * Save custom field.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customFieldsSaveCustomField$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customFieldsSaveCustomField(params: CustomFieldsSaveCustomField$Params, context?: HttpContext): Observable<void> {
    return this.customFieldsSaveCustomField$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
