/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { SendMailService } from './services/send-mail.service';
import { AuthService } from './services/auth.service';
import { UsersService } from './services/users.service';
import { UserDevicesService } from './services/user-devices.service';
import { AuditService } from './services/audit.service';
import { AccountService } from './services/account.service';
import { UsersManagementService } from './services/users-management.service';
import { RolesManagementService } from './services/roles-management.service';
import { SettingsService } from './services/settings.service';
import { StorageManagementService } from './services/storage-management.service';
import { HhaExchangeService } from './services/hha-exchange.service';
import { ContactService } from './services/contact.service';
import { ContactsService } from './services/contacts.service';
import { LookupService } from './services/lookup.service';
import { ReportsService } from './services/reports.service';
import { CustomReportsService } from './services/custom-reports.service';
import { ReportsV2Service } from './services/reports-v-2.service';
import { CallLogsService } from './services/call-logs.service';
import { GroupsReportsService } from './services/groups-reports.service';
import { UsersReportsService } from './services/users-reports.service';
import { ActivityDashboardService } from './services/activity-dashboard.service';
import { SystemService } from './services/system.service';
import { SystemAdminService } from './services/system-admin.service';
import { RecordingsService } from './services/recordings.service';
import { RecordingsV2Service } from './services/recordings-v-2.service';
import { MessagingService } from './services/messaging.service';
import { NotificationsService } from './services/notifications.service';
import { BasicSoftphoneService } from './services/basic-softphone.service';
import { PublicBasicSoftphoneService } from './services/public-basic-softphone.service';
import { AdvancedMessagingService } from './services/advanced-messaging.service';
import { MessagingAutomationService } from './services/messaging-automation.service';
import { MessagingTemplateService } from './services/messaging-template.service';
import { MessageBroadcastingService } from './services/message-broadcasting.service';
import { MessagingWhatsAppTemplateService } from './services/messaging-whats-app-template.service';
import { MessagingWhatsAppOnboardService } from './services/messaging-whats-app-onboard.service';
import { PhoneNumbersService } from './services/phone-numbers.service';
import { FaxService } from './services/fax.service';
import { FaxTemplateService } from './services/fax-template.service';
import { FaxReportService } from './services/fax-report.service';
import { PbxService } from './services/pbx.service';
import { HolidayGreetingsService } from './services/holiday-greetings.service';
import { PbxAccountsService } from './services/pbx-accounts.service';
import { PbxAccountButtonsService } from './services/pbx-account-buttons.service';
import { PbxSyncService } from './services/pbx-sync.service';
import { PbxDigiumService } from './services/pbx-digium.service';
import { FirewallService } from './services/firewall.service';
import { YealinkService } from './services/yealink.service';
import { PublicService } from './services/public.service';
import { TagsService } from './services/tags.service';
import { DownloadService } from './services/download.service';
import { SoftphoneService } from './services/softphone.service';
import { ApiService } from './services/api.service';
import { CustomFieldsService } from './services/custom-fields.service';
import { EventsFlowService } from './services/events-flow.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    SendMailService,
    AuthService,
    UsersService,
    UserDevicesService,
    AuditService,
    AccountService,
    UsersManagementService,
    RolesManagementService,
    SettingsService,
    StorageManagementService,
    HhaExchangeService,
    ContactService,
    ContactsService,
    LookupService,
    ReportsService,
    CustomReportsService,
    ReportsV2Service,
    CallLogsService,
    GroupsReportsService,
    UsersReportsService,
    ActivityDashboardService,
    SystemService,
    SystemAdminService,
    RecordingsService,
    RecordingsV2Service,
    MessagingService,
    NotificationsService,
    BasicSoftphoneService,
    PublicBasicSoftphoneService,
    AdvancedMessagingService,
    MessagingAutomationService,
    MessagingTemplateService,
    MessageBroadcastingService,
    MessagingWhatsAppTemplateService,
    MessagingWhatsAppOnboardService,
    PhoneNumbersService,
    FaxService,
    FaxTemplateService,
    FaxReportService,
    PbxService,
    HolidayGreetingsService,
    PbxAccountsService,
    PbxAccountButtonsService,
    PbxSyncService,
    PbxDigiumService,
    FirewallService,
    YealinkService,
    PublicService,
    TagsService,
    DownloadService,
    SoftphoneService,
    ApiService,
    CustomFieldsService,
    EventsFlowService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
