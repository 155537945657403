/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { FaxLogsResponse } from '../models/fax-logs-response';
import { FaxPhoneNumberModel } from '../models/fax-phone-number-model';
import { faxReportGetFaxPhoneNumbers } from '../fn/fax-report/fax-report-get-fax-phone-numbers';
import { FaxReportGetFaxPhoneNumbers$Params } from '../fn/fax-report/fax-report-get-fax-phone-numbers';
import { faxReportGetLogs } from '../fn/fax-report/fax-report-get-logs';
import { FaxReportGetLogs$Params } from '../fn/fax-report/fax-report-get-logs';
import { faxReportGetTopNumbersReport } from '../fn/fax-report/fax-report-get-top-numbers-report';
import { FaxReportGetTopNumbersReport$Params } from '../fn/fax-report/fax-report-get-top-numbers-report';
import { faxReportSummaryReport } from '../fn/fax-report/fax-report-summary-report';
import { FaxReportSummaryReport$Params } from '../fn/fax-report/fax-report-summary-report';
import { faxReportSummaryReportByDay } from '../fn/fax-report/fax-report-summary-report-by-day';
import { FaxReportSummaryReportByDay$Params } from '../fn/fax-report/fax-report-summary-report-by-day';
import { FaxSummaryReportByDayModel } from '../models/fax-summary-report-by-day-model';
import { FaxSummaryReportModel } from '../models/fax-summary-report-model';
import { FaxTopNumbersReportModel } from '../models/fax-top-numbers-report-model';

@Injectable({ providedIn: 'root' })
export class FaxReportService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `faxReportSummaryReport()` */
  static readonly FaxReportSummaryReportPath = '/api/fax-report/reports/summary';

  /**
   * Summary Report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxReportSummaryReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxReportSummaryReport$Response(params: FaxReportSummaryReport$Params, context?: HttpContext): Observable<StrictHttpResponse<FaxSummaryReportModel>> {
    return faxReportSummaryReport(this.http, this.rootUrl, params, context);
  }

  /**
   * Summary Report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxReportSummaryReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxReportSummaryReport(params: FaxReportSummaryReport$Params, context?: HttpContext): Observable<FaxSummaryReportModel> {
    return this.faxReportSummaryReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaxSummaryReportModel>): FaxSummaryReportModel => r.body)
    );
  }

  /** Path part for operation `faxReportSummaryReportByDay()` */
  static readonly FaxReportSummaryReportByDayPath = '/api/fax-report/reports/summary-by-day';

  /**
   * Summary Report By Day.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxReportSummaryReportByDay()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxReportSummaryReportByDay$Response(params: FaxReportSummaryReportByDay$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FaxSummaryReportByDayModel>>> {
    return faxReportSummaryReportByDay(this.http, this.rootUrl, params, context);
  }

  /**
   * Summary Report By Day.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxReportSummaryReportByDay$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxReportSummaryReportByDay(params: FaxReportSummaryReportByDay$Params, context?: HttpContext): Observable<Array<FaxSummaryReportByDayModel>> {
    return this.faxReportSummaryReportByDay$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FaxSummaryReportByDayModel>>): Array<FaxSummaryReportByDayModel> => r.body)
    );
  }

  /** Path part for operation `faxReportGetTopNumbersReport()` */
  static readonly FaxReportGetTopNumbersReportPath = '/api/fax-report/reports/top-numbers';

  /**
   * Get top fax phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxReportGetTopNumbersReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxReportGetTopNumbersReport$Response(params: FaxReportGetTopNumbersReport$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FaxTopNumbersReportModel>>> {
    return faxReportGetTopNumbersReport(this.http, this.rootUrl, params, context);
  }

  /**
   * Get top fax phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxReportGetTopNumbersReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxReportGetTopNumbersReport(params: FaxReportGetTopNumbersReport$Params, context?: HttpContext): Observable<Array<FaxTopNumbersReportModel>> {
    return this.faxReportGetTopNumbersReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FaxTopNumbersReportModel>>): Array<FaxTopNumbersReportModel> => r.body)
    );
  }

  /** Path part for operation `faxReportGetLogs()` */
  static readonly FaxReportGetLogsPath = '/api/fax-report/reports/logs';

  /**
   * Get logs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxReportGetLogs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxReportGetLogs$Response(params: FaxReportGetLogs$Params, context?: HttpContext): Observable<StrictHttpResponse<FaxLogsResponse>> {
    return faxReportGetLogs(this.http, this.rootUrl, params, context);
  }

  /**
   * Get logs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxReportGetLogs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxReportGetLogs(params: FaxReportGetLogs$Params, context?: HttpContext): Observable<FaxLogsResponse> {
    return this.faxReportGetLogs$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaxLogsResponse>): FaxLogsResponse => r.body)
    );
  }

  /** Path part for operation `faxReportGetFaxPhoneNumbers()` */
  static readonly FaxReportGetFaxPhoneNumbersPath = '/api/fax-report/reports/fax-phone-numbers';

  /**
   * Get fax phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxReportGetFaxPhoneNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxReportGetFaxPhoneNumbers$Response(params?: FaxReportGetFaxPhoneNumbers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FaxPhoneNumberModel>>> {
    return faxReportGetFaxPhoneNumbers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get fax phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxReportGetFaxPhoneNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxReportGetFaxPhoneNumbers(params?: FaxReportGetFaxPhoneNumbers$Params, context?: HttpContext): Observable<Array<FaxPhoneNumberModel>> {
    return this.faxReportGetFaxPhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FaxPhoneNumberModel>>): Array<FaxPhoneNumberModel> => r.body)
    );
  }

}
