export enum MenuGroupEnum {
    SettingsGroup = 'SettingsGroup',
    ReportsGroup = 'ReportsGroup',
    LegacyReportsGroup = 'LegacyReportsGroup',
    CallLogsGroup = 'CallLogsGroup',
    RecordingsGroup = 'RecordingsGroup',
    PhoneNumbersGroup = 'PhoneNumbersGroup',
    CustomFieldsGroup = 'CustomFieldsGroup',
    FirewallGroup = 'FirewallGroup',
    MessagingGroup = 'MessagingGroup',
    ProMessagingGroup = 'ProMessagingGroup',
    FaxGroup = 'FaxGroup',
    PbxGroup = 'PbxGroup',
    ContactGroup = 'ContactGroup',
    TagsGroup = 'TagsGroup',
    SoftPhoneGroup = 'SoftPhoneGroup',
    HhaGroup = 'HhaGroup',
    EventsFlowGroup = 'EventsFlowGroup',
    DownloadsGroup = 'DownloadsGroup',
    VideoConferenceGroup = 'VideoConferenceGroup',
}
