/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BillingInfoResponse } from '../models/billing-info-response';
import { ContactTagLookupModel } from '../models/contact-tag-lookup-model';
import { MessageModel } from '../models/message-model';
import { messagingDeleteFullConversationByGroup } from '../fn/messaging/messaging-delete-full-conversation-by-group';
import { MessagingDeleteFullConversationByGroup$Params } from '../fn/messaging/messaging-delete-full-conversation-by-group';
import { messagingDeleteMessageBody } from '../fn/messaging/messaging-delete-message-body';
import { MessagingDeleteMessageBody$Params } from '../fn/messaging/messaging-delete-message-body';
import { messagingGetMessages } from '../fn/messaging/messaging-get-messages';
import { MessagingGetMessages$Params } from '../fn/messaging/messaging-get-messages';
import { messagingGetMessagingGroups } from '../fn/messaging/messaging-get-messaging-groups';
import { MessagingGetMessagingGroups$Params } from '../fn/messaging/messaging-get-messaging-groups';
import { messagingGetMessagingGroupsV2 } from '../fn/messaging/messaging-get-messaging-groups-v-2';
import { MessagingGetMessagingGroupsV2$Params } from '../fn/messaging/messaging-get-messaging-groups-v-2';
import { messagingGetMessagingLimits } from '../fn/messaging/messaging-get-messaging-limits';
import { MessagingGetMessagingLimits$Params } from '../fn/messaging/messaging-get-messaging-limits';
import { messagingGetMyMessagingPhoneNumbers } from '../fn/messaging/messaging-get-my-messaging-phone-numbers';
import { MessagingGetMyMessagingPhoneNumbers$Params } from '../fn/messaging/messaging-get-my-messaging-phone-numbers';
import { MessagingGroupModel } from '../models/messaging-group-model';
import { MessagingGroupsGridResponse } from '../models/messaging-groups-grid-response';
import { messagingLookupContactsAndTags } from '../fn/messaging/messaging-lookup-contacts-and-tags';
import { MessagingLookupContactsAndTags$Params } from '../fn/messaging/messaging-lookup-contacts-and-tags';
import { messagingSendMessage } from '../fn/messaging/messaging-send-message';
import { MessagingSendMessage$Params } from '../fn/messaging/messaging-send-message';
import { SmsPhoneNumberModel } from '../models/sms-phone-number-model';

@Injectable({ providedIn: 'root' })
export class MessagingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `messagingSendMessage()` */
  static readonly MessagingSendMessagePath = '/api/messaging/send-message';

  /**
   * Send Message.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingSendMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingSendMessage$Response(params: MessagingSendMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<MessageModel>> {
    return messagingSendMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Send Message.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingSendMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingSendMessage(params: MessagingSendMessage$Params, context?: HttpContext): Observable<MessageModel> {
    return this.messagingSendMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageModel>): MessageModel => r.body)
    );
  }

  /** Path part for operation `messagingGetMyMessagingPhoneNumbers()` */
  static readonly MessagingGetMyMessagingPhoneNumbersPath = '/api/messaging/my-messaging-phone-numbers';

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingGetMyMessagingPhoneNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingGetMyMessagingPhoneNumbers$Response(params?: MessagingGetMyMessagingPhoneNumbers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SmsPhoneNumberModel>>> {
    return messagingGetMyMessagingPhoneNumbers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingGetMyMessagingPhoneNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingGetMyMessagingPhoneNumbers(params?: MessagingGetMyMessagingPhoneNumbers$Params, context?: HttpContext): Observable<Array<SmsPhoneNumberModel>> {
    return this.messagingGetMyMessagingPhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SmsPhoneNumberModel>>): Array<SmsPhoneNumberModel> => r.body)
    );
  }

  /** Path part for operation `messagingGetMessagingLimits()` */
  static readonly MessagingGetMessagingLimitsPath = '/api/messaging/messaging-limits';

  /**
   * Get messaging limits and current usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingGetMessagingLimits()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingGetMessagingLimits$Response(params?: MessagingGetMessagingLimits$Params, context?: HttpContext): Observable<StrictHttpResponse<BillingInfoResponse>> {
    return messagingGetMessagingLimits(this.http, this.rootUrl, params, context);
  }

  /**
   * Get messaging limits and current usage.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingGetMessagingLimits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingGetMessagingLimits(params?: MessagingGetMessagingLimits$Params, context?: HttpContext): Observable<BillingInfoResponse> {
    return this.messagingGetMessagingLimits$Response(params, context).pipe(
      map((r: StrictHttpResponse<BillingInfoResponse>): BillingInfoResponse => r.body)
    );
  }

  /** Path part for operation `messagingGetMessagingGroups()` */
  static readonly MessagingGetMessagingGroupsPath = '/api/messaging/messaging-groups/{phoneNumber}';

  /**
   * Get messaging phone number groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingGetMessagingGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingGetMessagingGroups$Response(params: MessagingGetMessagingGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MessagingGroupModel>>> {
    return messagingGetMessagingGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * Get messaging phone number groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingGetMessagingGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingGetMessagingGroups(params: MessagingGetMessagingGroups$Params, context?: HttpContext): Observable<Array<MessagingGroupModel>> {
    return this.messagingGetMessagingGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MessagingGroupModel>>): Array<MessagingGroupModel> => r.body)
    );
  }

  /** Path part for operation `messagingGetMessagingGroupsV2()` */
  static readonly MessagingGetMessagingGroupsV2Path = '/api/messaging/messaging-groups-v2/{phoneNumber}';

  /**
   * Get messaging phone number groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingGetMessagingGroupsV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingGetMessagingGroupsV2$Response(params: MessagingGetMessagingGroupsV2$Params, context?: HttpContext): Observable<StrictHttpResponse<MessagingGroupsGridResponse>> {
    return messagingGetMessagingGroupsV2(this.http, this.rootUrl, params, context);
  }

  /**
   * Get messaging phone number groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingGetMessagingGroupsV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingGetMessagingGroupsV2(params: MessagingGetMessagingGroupsV2$Params, context?: HttpContext): Observable<MessagingGroupsGridResponse> {
    return this.messagingGetMessagingGroupsV2$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessagingGroupsGridResponse>): MessagingGroupsGridResponse => r.body)
    );
  }

  /** Path part for operation `messagingGetMessages()` */
  static readonly MessagingGetMessagesPath = '/api/messaging/get-messages';

  /**
   * Get messages from/to specific sender.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingGetMessages()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingGetMessages$Response(params: MessagingGetMessages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MessageModel>>> {
    return messagingGetMessages(this.http, this.rootUrl, params, context);
  }

  /**
   * Get messages from/to specific sender.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingGetMessages$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingGetMessages(params: MessagingGetMessages$Params, context?: HttpContext): Observable<Array<MessageModel>> {
    return this.messagingGetMessages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MessageModel>>): Array<MessageModel> => r.body)
    );
  }

  /** Path part for operation `messagingDeleteMessageBody()` */
  static readonly MessagingDeleteMessageBodyPath = '/api/messaging/message/{messageId}';

  /**
   * Delete Message body.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingDeleteMessageBody()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingDeleteMessageBody$Response(params: MessagingDeleteMessageBody$Params, context?: HttpContext): Observable<StrictHttpResponse<MessageModel>> {
    return messagingDeleteMessageBody(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Message body.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingDeleteMessageBody$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingDeleteMessageBody(params: MessagingDeleteMessageBody$Params, context?: HttpContext): Observable<MessageModel> {
    return this.messagingDeleteMessageBody$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageModel>): MessageModel => r.body)
    );
  }

  /** Path part for operation `messagingDeleteFullConversationByGroup()` */
  static readonly MessagingDeleteFullConversationByGroupPath = '/api/messaging/conversation-by-group/{groupKey}';

  /**
   * Delete Full Conversation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingDeleteFullConversationByGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingDeleteFullConversationByGroup$Response(params: MessagingDeleteFullConversationByGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return messagingDeleteFullConversationByGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Full Conversation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingDeleteFullConversationByGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingDeleteFullConversationByGroup(params: MessagingDeleteFullConversationByGroup$Params, context?: HttpContext): Observable<boolean> {
    return this.messagingDeleteFullConversationByGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `messagingLookupContactsAndTags()` */
  static readonly MessagingLookupContactsAndTagsPath = '/api/messaging/lookup-contacts';

  /**
   * Lookup contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingLookupContactsAndTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingLookupContactsAndTags$Response(params: MessagingLookupContactsAndTags$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContactTagLookupModel>>> {
    return messagingLookupContactsAndTags(this.http, this.rootUrl, params, context);
  }

  /**
   * Lookup contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingLookupContactsAndTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingLookupContactsAndTags(params: MessagingLookupContactsAndTags$Params, context?: HttpContext): Observable<Array<ContactTagLookupModel>> {
    return this.messagingLookupContactsAndTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactTagLookupModel>>): Array<ContactTagLookupModel> => r.body)
    );
  }

}
