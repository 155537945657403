/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { sendMailGetEmail } from '../fn/send-mail/send-mail-get-email';
import { SendMailGetEmail$Params } from '../fn/send-mail/send-mail-get-email';

@Injectable({ providedIn: 'root' })
export class SendMailService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sendMailGetEmail()` */
  static readonly SendMailGetEmailPath = '/api/send-mail/test-all-emails';

  /**
   * Send All available emails for testing.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendMailGetEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendMailGetEmail$Response(params: SendMailGetEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return sendMailGetEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * Send All available emails for testing.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendMailGetEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendMailGetEmail(params: SendMailGetEmail$Params, context?: HttpContext): Observable<boolean> {
    return this.sendMailGetEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
