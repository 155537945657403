/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminFixTemplateRoles } from '../fn/system-admin/admin-fix-template-roles';
import { AdminFixTemplateRoles$Params } from '../fn/system-admin/admin-fix-template-roles';
import { adminTestStorage } from '../fn/system-admin/admin-test-storage';
import { AdminTestStorage$Params } from '../fn/system-admin/admin-test-storage';
import { adminUpdateDomainsCdrCache } from '../fn/system-admin/admin-update-domains-cdr-cache';
import { AdminUpdateDomainsCdrCache$Params } from '../fn/system-admin/admin-update-domains-cdr-cache';

@Injectable({ providedIn: 'root' })
export class SystemAdminService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminTestStorage()` */
  static readonly AdminTestStoragePath = '/api/system-admin/test-storage';

  /**
   * Sync historical faxes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminTestStorage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminTestStorage$Response(params: AdminTestStorage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminTestStorage(this.http, this.rootUrl, params, context);
  }

  /**
   * Sync historical faxes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminTestStorage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminTestStorage(params: AdminTestStorage$Params, context?: HttpContext): Observable<void> {
    return this.adminTestStorage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminFixTemplateRoles()` */
  static readonly AdminFixTemplateRolesPath = '/api/system-admin/fix-templates-roles';

  /**
   * Fix SMS templates roles (use only first time).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFixTemplateRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFixTemplateRoles$Response(params?: AdminFixTemplateRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminFixTemplateRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * Fix SMS templates roles (use only first time).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFixTemplateRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFixTemplateRoles(params?: AdminFixTemplateRoles$Params, context?: HttpContext): Observable<void> {
    return this.adminFixTemplateRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminUpdateDomainsCdrCache()` */
  static readonly AdminUpdateDomainsCdrCachePath = '/api/system-admin/update-domains-cdr-cache';

  /**
   * Fix SMS templates roles (use only first time).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUpdateDomainsCdrCache()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUpdateDomainsCdrCache$Response(params?: AdminUpdateDomainsCdrCache$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminUpdateDomainsCdrCache(this.http, this.rootUrl, params, context);
  }

  /**
   * Fix SMS templates roles (use only first time).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUpdateDomainsCdrCache$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUpdateDomainsCdrCache(params?: AdminUpdateDomainsCdrCache$Params, context?: HttpContext): Observable<void> {
    return this.adminUpdateDomainsCdrCache$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
