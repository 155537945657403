/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { auditGetAuditCategories } from '../fn/audit/audit-get-audit-categories';
import { AuditGetAuditCategories$Params } from '../fn/audit/audit-get-audit-categories';
import { auditGetAuditTypes } from '../fn/audit/audit-get-audit-types';
import { AuditGetAuditTypes$Params } from '../fn/audit/audit-get-audit-types';
import { auditGetList } from '../fn/audit/audit-get-list';
import { AuditGetList$Params } from '../fn/audit/audit-get-list';
import { auditGetStatus } from '../fn/audit/audit-get-status';
import { AuditGetStatus$Params } from '../fn/audit/audit-get-status';
import { AuditGridResponse } from '../models/audit-grid-response';
import { LookupModel } from '../models/lookup-model';

@Injectable({ providedIn: 'root' })
export class AuditService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `auditGetStatus()` */
  static readonly AuditGetStatusPath = '/api/audit/status';

  /**
   * Get Audit Service Status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `auditGetStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  auditGetStatus$Response(params?: AuditGetStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return auditGetStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Audit Service Status.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `auditGetStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  auditGetStatus(params?: AuditGetStatus$Params, context?: HttpContext): Observable<string> {
    return this.auditGetStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `auditGetAuditTypes()` */
  static readonly AuditGetAuditTypesPath = '/api/audit/types';

  /**
   * Get audit types.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `auditGetAuditTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  auditGetAuditTypes$Response(params?: AuditGetAuditTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LookupModel>>> {
    return auditGetAuditTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get audit types.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `auditGetAuditTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  auditGetAuditTypes(params?: AuditGetAuditTypes$Params, context?: HttpContext): Observable<Array<LookupModel>> {
    return this.auditGetAuditTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LookupModel>>): Array<LookupModel> => r.body)
    );
  }

  /** Path part for operation `auditGetAuditCategories()` */
  static readonly AuditGetAuditCategoriesPath = '/api/audit/categories';

  /**
   * Get audit categories.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `auditGetAuditCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  auditGetAuditCategories$Response(params?: AuditGetAuditCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LookupModel>>> {
    return auditGetAuditCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * Get audit categories.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `auditGetAuditCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  auditGetAuditCategories(params?: AuditGetAuditCategories$Params, context?: HttpContext): Observable<Array<LookupModel>> {
    return this.auditGetAuditCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LookupModel>>): Array<LookupModel> => r.body)
    );
  }

  /** Path part for operation `auditGetList()` */
  static readonly AuditGetListPath = '/api/audit/list';

  /**
   * Get latest audit.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `auditGetList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  auditGetList$Response(params: AuditGetList$Params, context?: HttpContext): Observable<StrictHttpResponse<AuditGridResponse>> {
    return auditGetList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get latest audit.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `auditGetList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  auditGetList(params: AuditGetList$Params, context?: HttpContext): Observable<AuditGridResponse> {
    return this.auditGetList$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuditGridResponse>): AuditGridResponse => r.body)
    );
  }

}
