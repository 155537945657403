/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GetMessagesModel } from '../../models/get-messages-model';
import { MessageModel } from '../../models/message-model';

export interface MessagingGetMessages$Params {
      body: GetMessagesModel
}

export function messagingGetMessages(http: HttpClient, rootUrl: string, params: MessagingGetMessages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MessageModel>>> {
  const rb = new RequestBuilder(rootUrl, messagingGetMessages.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<MessageModel>>;
    })
  );
}

messagingGetMessages.PATH = '/api/messaging/get-messages';
