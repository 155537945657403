import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';
import { timer } from 'rxjs';
import { VersionCheckerDialogComponent } from '../../dialogs/version-checker-dialog/version-checker-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckerService {
  private _updateAvailable = false;

  private readonly _everyFiveMinutes$ = timer(0, 5 * 60 * 1000);

  constructor(private readonly _updates: SwUpdate, private readonly _dialog: MatDialog) {}

  public initCheckForUpdates(): void {
    console.log('Init Check For Updates');
    if (this._updates.isEnabled) {
      this._everyFiveMinutes$.subscribe(async () => {
        try {
          const isUpdate = this._updateAvailable || (await this._updates.checkForUpdate());
          console.log(`checking for updates: is update available: ${isUpdate}`);
          if (isUpdate) {
            this._updateAvailable = true;
            this.openVersionCheckerDialog();
          }
        } catch (err) {
          console.error('Failed to check for updates:', err);
        }
      });
    }
  }

  private openVersionCheckerDialog(): void {
    this._dialog.open(VersionCheckerDialogComponent, {
      width: '500px',
      disableClose: true,
    });
  }
}
