/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { faxTemplateGetFaxTemplate } from '../fn/fax-template/fax-template-get-fax-template';
import { FaxTemplateGetFaxTemplate$Params } from '../fn/fax-template/fax-template-get-fax-template';
import { faxTemplateGetFaxTemplates } from '../fn/fax-template/fax-template-get-fax-templates';
import { FaxTemplateGetFaxTemplates$Params } from '../fn/fax-template/fax-template-get-fax-templates';
import { FaxTemplateModel } from '../models/fax-template-model';
import { faxTemplateSaveFaxTemplate } from '../fn/fax-template/fax-template-save-fax-template';
import { FaxTemplateSaveFaxTemplate$Params } from '../fn/fax-template/fax-template-save-fax-template';

@Injectable({ providedIn: 'root' })
export class FaxTemplateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `faxTemplateGetFaxTemplates()` */
  static readonly FaxTemplateGetFaxTemplatesPath = '/api/fax-template/templates';

  /**
   * Get all fax templates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxTemplateGetFaxTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxTemplateGetFaxTemplates$Response(params?: FaxTemplateGetFaxTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FaxTemplateModel>>> {
    return faxTemplateGetFaxTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all fax templates.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxTemplateGetFaxTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxTemplateGetFaxTemplates(params?: FaxTemplateGetFaxTemplates$Params, context?: HttpContext): Observable<Array<FaxTemplateModel>> {
    return this.faxTemplateGetFaxTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FaxTemplateModel>>): Array<FaxTemplateModel> => r.body)
    );
  }

  /** Path part for operation `faxTemplateSaveFaxTemplate()` */
  static readonly FaxTemplateSaveFaxTemplatePath = '/api/fax-template/save';

  /**
   * Add/Edit fax template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxTemplateSaveFaxTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxTemplateSaveFaxTemplate$Response(params: FaxTemplateSaveFaxTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<FaxTemplateModel>> {
    return faxTemplateSaveFaxTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Add/Edit fax template.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxTemplateSaveFaxTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxTemplateSaveFaxTemplate(params: FaxTemplateSaveFaxTemplate$Params, context?: HttpContext): Observable<FaxTemplateModel> {
    return this.faxTemplateSaveFaxTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaxTemplateModel>): FaxTemplateModel => r.body)
    );
  }

  /** Path part for operation `faxTemplateGetFaxTemplate()` */
  static readonly FaxTemplateGetFaxTemplatePath = '/api/fax-template/template/{id}';

  /**
   * Get template by Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxTemplateGetFaxTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxTemplateGetFaxTemplate$Response(params: FaxTemplateGetFaxTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return faxTemplateGetFaxTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Get template by Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxTemplateGetFaxTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxTemplateGetFaxTemplate(params: FaxTemplateGetFaxTemplate$Params, context?: HttpContext): Observable<void> {
    return this.faxTemplateGetFaxTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
